import React, { useState, useEffect, useRef } from 'react'
import '../inward/inwarddbstatus.css';
import InwardContainerHeader from './InwardContainerHeader';
import InwardLogService from '../../services/inwardlog.service';
import {Link, useLocation,useNavigate } from 'react-router-dom';
import Sign from '../../components/Sign';
import SignatureCanvas from 'react-signature-canvas';
import InwardDeliverySheet from '../../services/inwarddeliverysheet.service';
import moment from 'moment';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
const Inwarddbstatus = () => {

    // const [showtable, setShowTable] = useState(true)
    const [showform, setShowForm] = useState(false);
    const [tableWidth, setTableWidth] = useState('100%');

    const ShowTable = () => {
        setTableWidth(tableWidth === '100%' ? '70%' : '100%');
        if (showform == false) {
            // setShowTable(true)
            setShowForm(true)


        } else {
            // setShowTable(false)
            setShowForm(false)
        }


    }
    const [show, setShow] = useState(false);
    function hidetable() {
        if (show === false) {
            setShow(true)
        } else {
            setShow(false)
        }
    }

    const inwarddbData = [{


    }]

    const [drsNumber, setdrsNumber] = useState()
    const navigate = useNavigate();
    const [rowData, setRowData] = useState([]);
    const [drsDetails, setDrsDetails] = useState([]);
    const [selectedRecords, setSelectedRecords] = useState();


    interface selectRowType {
        mode: any;
        clickToSelect: boolean;  
        style: any;
        onSelect: any;
      }
    
      const selectRow: selectRowType = {
        mode: "radio",
        clickToSelect: true,
        style: { backgroundColor: '#c8e6c9' },
        onSelect: (row: any) => {
          setSelectedRecords(row.id);
        }
      }

    // const shipmentMode = [{
    //   modeName: modeName,
    // }]
    const getDrsList = () => {
        InwardDeliverySheet.getPendingDrs().then(
            (data) => {
                setRowData(data);
                        }
        ).catch((error) => {
            console.log(error);
            console.log("server responding");

        })
    }

    useEffect(() => {
        getDrsList()
    }, [])

    const handleRowClick = (drsId: number) => {
        console.log("drsNUmber", drsId)
        InwardDeliverySheet.getDrsDetailsbyId(drsId).then(
            (data) => {
                setDrsDetails(data);
            }
        ).catch((error) => {
            console.log(error);
            console.log("server responding");
        })
    };
    const [inwarddetailStatus, setinwarddetailStatus] = useState('');
    const [receivedBy, setreceivedBy] = useState('');
    const [remark, setremark] = useState('');
    const [inwardDate, setinwardDate] = useState('');
    const [inwardTime, setinwardTime] = useState('');
    const [updatedBy, setupdatedBy] = useState('');
    const [deliverySign, setdeliverySign] = useState('');
    const [showSign, setShowSign] = useState(false);
    const [hideSign, setHideSign] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setinwarddetailStatus(event.target.value);
        // console.log(inwarddetailStatus)
        if (showSign === false) {
            setShowSign(false)

        } else {
            // setHideSign(showSign);
            setShowSign(false)

        }
    };
    const handleOptionChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setinwarddetailStatus(event.target.value);
        // console.log(inwarddetailStatus)
        if (showSign === false) {
            setShowSign(true)

        } else {
            setHideSign(showSign);
            setShowSign(false)

        }
    };
    const HandleSubmit = () => {
        const inputValue = inwarddetailStatus;
        InwardLogService.postupdateinwarddetailstatus(inwarddetailStatus, receivedBy, remark, inwardDate, inwardTime, updatedBy, deliverySign).then(
            (data) => {
                // console.log(data.statuscode)
                if (data.statuscode === 200) {
                    setinwarddetailStatus(data.message[0].inwarddetailStatus);
                }
            }
        ).catch((error) => {
            console.log(error);
            console.log("server responding");
        })
    }
    function displaySign() {
    }
    const signatureCanvasRef = useRef<any>(null);

    const saveSignature = () => {
        if (signatureCanvasRef.current) {
            const signatureImage = signatureCanvasRef.current.getTrimmedCanvas().toDataURL('image/png');
            setdeliverySign(signatureImage);
            // console.log(deliverySign)
        }
    };
    const clearSignature = () => {
        if (signatureCanvasRef.current) {
            signatureCanvasRef.current.clear();
        }
    }
    
    const columns: ColumnDescription<selectRowType>[] = [
      { dataField: 'AWB', formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1;
      }, text: 'Sr. No',  align: 'center'},
      { dataField: 'AWB', text: 'AWB No' },
      { dataField: 'inwardDate', text: 'Inward Date', headerAlign: 'left'},
      { dataField: 'inwardType', text: 'Type', headerAlign: 'left', style: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }, classes:"showinline" },
      { dataField: 'inwardQuantity', text: 'Pcs.',  align: 'center' , style : { fontWeight :'bold', width : "75px"} },
      { dataField: 'consignerName', text: 'Consignor',  align: 'center'},
      { dataField: 'employeeName', text: 'Employee',  align: 'center'},
     
  
    ];
    const filteredData = Array.isArray(drsDetails)
    ? drsDetails.filter((selectedRecords) => {
        const values = Object.values(selectedRecords);
        return values.some(
          (value: unknown): boolean => {
            if (typeof value === 'string' || typeof value === 'number') {
              const stringValue = String(value).toLowerCase();
              return stringValue.includes(searchQuery.toLowerCase());
            }
            return false;
          }
        );
      })
    : [];
    return (
        <>
            <InwardContainerHeader />
            <div className='mb-10'>
                <div style={{ margin: "0px" }}>
                    <div className='' style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}>
                        <div className="container col-10" style={{padding:'0px'}}>
                            <div className="themeColor"  > Update Inward Status  </div>                              
                             <div className='col-12 inline' style={{padding:'0px'}}>
                                <div className='col-4' style={{padding:'0px'}}>
                                <div className='col-12' style={{padding :'0px'}}>
                                        <div className='width  form'>
                                            <table onClick={ShowTable} className="container  table-bordered" >
                                                <thead>
                                                    <tr style={{ textAlign: "left", border: "1px solid grey" }}>
                                                        <th scope="col" className='left-data' style={{width :"10%"}}>Sr No</th>
                                                        <th scope="col" className='left-data' style={{width :"30%"}}>Date Time</th>
                                                        <th scope="col" className='left-data' style={{width :"30%"}}>DRS No</th>
                                                        <th scope="col" className='left-data' style={{width :"30%"}}>Staff</th>
                                                    </tr>
                                                </thead>

                                                <tbody style={{ border: "1px solid grey", fontSize:'10px' }}>
                                                    {rowData.map((item, index) =>{
                                                        return (
                                                        <tr key={"drs"+index} onClick={() => handleRowClick(item['id'])}>
                                                        <td className='left-data' style={{width :"10%"}}>{index + 1}</td>
                                                        <td className='left-data' style={{width :"30%"}}>{moment(item['drsDate']).format("DD/MM/yyyy")}</td>
                                                        <td className='left-data' style={{width :"30%"}}>{item['drsNumber']}</td>
                                                        <td className='left-data' style={{width :"30%",  whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{item['deliveryBoyName']}</td>
                                                        
                                                    </tr>)
                                                    }   )}
                                                </tbody>
                                            </table>

                                        </div>
                                   </div>
                                </div>
                                <div className='col-8'>
                                <div className="table-responsive">
                            <BootstrapTable rowStyle={{ fontSize: '15px', padding: '0px', whiteSpace: 'nowrap', width: '100%'}}
                            keyField='id'
                            striped
                            hover
                            condensed
                            data={filteredData}
                            columns={columns}                            
                            selectRow={selectRow}
                            filter={filterFactory()}
                            filterPosition="top"
                            />
                            </div>
                                </div>

</div>
                        
                        <div className='card container'>
                            <div className='bottombtns rbtn col-12 ' style={{ justifyContent: "start" }} >
                                {/* <div className=' inline'>
                                    <input className=' radio-btn ' type="checkbox" id="" name="" ></input>
                                    <span className=' radio-btn'>Intra Company</span>
                                </div> */}
                                {/* <div className=' inline'>
                                    <input className=' radio-btn ml-4' type="checkbox" id="" name="" ></input>
                                    <span className=''>SelectAll</span>
                                </div> */}

                                {/* <div className=' inline'>
                                    <span className=' radio-btn ml-4' >Total Count :1</span>

                                </div>
                                <div className=' inline'>
                                    <input className=' radio-btn ml-4' type="checkbox" id="" name="" ></input>
                                    <span className=''>PendingList</span>
                                </div> */}
                            </div>
                            <div className='bottombtns'>

                                <div className=' inline col-4' style={{ display: "flex", justifyContent: "left", marginTop: "5px" }}>
                                    <div className='incolumn ' style={{}}>
                                        <div className='col-6  '>
                                            <div className=' Radiobtn' onClick={displaySign}><input type='radio' className='radio-btn' name='b'
                                                value="Delivered"
                                                checked={inwarddetailStatus === 'Delivered'}
                                                onChange={handleOptionChange1}

                                            /> Delivered</div>
                                            <div className=' Radiobtn'> <input type='radio' className='radio-btn' name='b'
                                                value="Redirected"
                                                checked={inwarddetailStatus === 'Redirected'}
                                                onChange={handleOptionChange}
                                            /> Redirected</div>
                                            <div className=' Radiobtn'> <input type='radio' className='radio-btn' name='b'
                                                value="Unclaimed"
                                                checked={inwarddetailStatus === 'Unclaimed'}
                                                onChange={handleOptionChange}
                                            /> Unclaimed</div>

                                        </div>
                                    </div>
                                    <div className='incolumn' style={{}}>
                                        <div className='col-6  '>
                                            <div className=' Radiobtn'><input type='radio' className='radio-btn' name='b'
                                                value="Reassign"
                                                checked={inwarddetailStatus === 'Reassign'}
                                                onChange={handleOptionChange}
                                            /> Reassign</div>
                                            <div className=' Radiobtn'> <input type='radio' className='radio-btn' name='b'
                                                value="Undelivered"
                                                checked={inwarddetailStatus === 'Undelivered'}
                                                onChange={handleOptionChange}
                                            /> Undelivered</div>
                                            <div className=' Radiobtn'> <input type='radio' className='radio-btn' name='b'
                                                value="OnHold"
                                                checked={inwarddetailStatus === 'OnHold'}
                                                onChange={handleOptionChange}
                                            /> OnHold</div>

                                        </div>
                                    </div>

                                </div>




                                <div className='col-8' style={{ display: "flex", marginRight: "30px", marginTop: "3px" }}>
                                    {/* <div className='4' >
                            <label className='col'>Recived By</label>
                            <input className='col' type="text"
                                value={receivedBy} name='receivedBy' onChange={e => setreceivedBy(e.target.value)} />
                        </div> */}
                                    <div className='col-3'>
                                        <label className='col'>Status Date</label>
                                        <input className='col' type="Date"
                                            value={inwardDate} name='inwardDate' onChange={e => setinwardDate(e.target.value)} />
                                    </div>
                                    <div className='col-3'>
                                        <label className='col'>Status Time</label>
                                        <input className='col-12' type="Time" style={{}}
                                            value={inwardTime} name='inwardTime' onChange={e => setinwardTime(e.target.value)} />
                                    </div>
                                    <div className='col-6'>
                                        <label className='col'>Remark</label>
                                        <input className='col-12' type="text" style={{ marginLeft: "10px" }}
                                                    value={remark} name='remark' onChange={e => setremark(e.target.value)} />
                                    </div>
                                 </div>



                            </div>

                            <div className='col-12 inline container center mb-2'>
                                {showSign && (<div className=' '>
                                    <div><h5>Sign Box</h5>
                                    </div>
                                    {/* <Sign /> */}
                                    <div>
                                        <SignatureCanvas
                                            ref={signatureCanvasRef}
                                            penColor="black"
                                            canvasProps={{ width: 300, height: 100, className: 'sigCanvas border' }}
                                        // canvasProps={{ width, height }}
                                        />
                                        <div>
                                            <button className='btn2 col-6' onClick={saveSignature}>Save Signature</button>
                                            <button className='btn2 col-6' onClick={clearSignature}>Clear</button>
                                        </div>
                                    </div>
                                </div>
                                )}
                                <div className='col-6' style={{ display: "flex", flexDirection: "column" }}>
                                    <div>
                                        
                                        <div className='col-12 incolumn ' style={{ display: "flex", padding: "20px", justifyContent: "" }} >

                                            <div className='remark '>

                                                {showSign && (
                                                    <div> <label className='col-0'>Received By</label>
                                                         <input className='col-8' type="text" style={{ marginLeft: "10px" }}
                                                            value={receivedBy} name='receivedBy' onChange={e => setreceivedBy(e.target.value)} />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className='col-6 incolumn ' style={{ display: "flex", padding: "20px", justifyContent: "" }} >
                            <div className='remark' >
                                {showSign && (
                                    <div> <label className='col-0'>Recived By</label>
                                        <input className='col-8 ml-3' type="text"
                                            value={receivedBy} name='receivedBy' onChange={e => setreceivedBy(e.target.value)} />
                                    </div>
                                )}
                            </div>
                        </div> */}


                        
                                    {/* <div className='db-btns'>
                                        <div className=' inline btn'>
                                            <button className="dbformbtn btn2 " type="submit"
                                                onClick={HandleSubmit}> Save</button>
                                            <button className="dbformbtn1 btn2 " type="submit"  ><i className="fa fa-close"></i> Cancel</button>
                                            <button className="dbformbtn btn2" type="submit" > <i className="fa fa-sign-out" aria-hidden="true"></i> Close</button>
                                        </div>
                                    </div> */}
                                </div>
                                
                            </div>
                        </div>
                        <div className='btn d-flex justify-content-center'>
                            <button className="btn2 col-2 col-sm-1" type="button" onClick={HandleSubmit}><i className="fa fa-save"></i> Save</button> 
                            <button className="btn2 col-2 col-sm-1" > <i className="fa fa-close" ></i> Clear</button>
                            <Link className="btn2 col-2 col-sm-1" to="/inwardloglist" type='button'>  <i className="fa fa-sign-out"></i> Close </Link>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </>

    )
}

export default Inwarddbstatus