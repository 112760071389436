import React, { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import InwardLogService from '../../services/inwardlog.service';
import { Link, Navigate, useNavigate } from 'react-router-dom'
import ReportContainerHeader from './ReportContainerHeader';
// import MasterDataService from '../../services/masterData.service';


const InwardMail = () => {
    const [fromDate, setfromDate] = useState('');
    const [toDate, settoDate] = useState('');
    const [to, setto] = useState('');
    const [title, settitle] = useState('');
    const [branch, setbranch] = useState('');
    const [receiver, setreceiver] = useState('');
    const [subject, setsubject] = useState('');
    const [content, setcontent] = useState('');
    const [signature, setsignature] = useState('');

    const mailProvider = [{

        fromDate: "2023-06-23",
        toDate: "2023-06-29",
        to: "kotwalnilesh001@gmail.com",
        title: title,
        branch: 2,
        receiver: "Sanyog Softwares",
        subject: "Daily MIS Report Test Mail",
        content: "Attaching herewith excel file of auto generated mis report for branch no. 2.",
        signature: "Regards,\nSanyog Softwares,\nPune - 412015."

    }]


    const navigate = useNavigate();

    function sendMail() {

        InwardLogService.PostInwardMail(title, signature, receiver, subject, content, to, fromDate, toDate).then(
            (data) => {
                console.log("Resistration Successful");
                console.log(data.statuscode)
                if (data.statuscode === 200) {
                    settitle(data.message[0].title);
                    navigate('/dashboard');
                }
            }

        ).catch((error) => {
            console.log(error);
            console.log("Server Responding");
        }
        )
    }




    return (
        <div>
            <ReportContainerHeader />

            <div style={{}}>
                <div className=''>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <div className="redline col-8 " style={{marginTop:"20px"}}><p className='m-0' style={{ marginLeft: "10px", alignItems: "center" }}>Inward Mail<a /></p></div>
                    </div>
                    <div className="form card container col-8" style={{display:"flex",justifyContent:"center",flexDirection:"column"}}>
                        {/* <div className=" "> */}



                        <div className="inline width mail-filters">

                            <div className="col-1 fromdate">
                                <label>Branch</label>
                            </div>
                            <div className="col-3">
                                <select className="select" name='branch'>
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                    <option value={3}>3</option>
                                </select>
                            </div>
                            <div className="col-2 fromdate"><label>From Date</label></div>
                            <div className="filters"><input className="select" type="date" name='fromDate' value={fromDate} onChange={e => setfromDate(e.target.value)}></input></div>
                            <div className="col-1 fromdate"><label>To Date</label></div>
                            <div className="filters"><input className="select" type="date" name='toDate' value={toDate} onChange={e => settoDate(e.target.value)}></input></div>


                        </div>
                        {/* </div> */}
                        <div className="width container card padding"style={{marginBottom:"10px"}}>

                            <div className='mail-inputs col-12 inline'>
                                <div className='col-3 mail-label'> <label className='col-8 '> Title / Receiver</label></div>

                                <input type='text' className='col-8 col-sm-2' name='title' value={title} onChange={e => settitle(e.target.value)} />
                                <input type='text' className='col-8 col-sm-6' name='receiver' value={receiver} onChange={e => setreceiver(e.target.value)} />
                            </div>

                            <div className='mail-inputs col-12 inline'>
                                <div className='col-3 mail-label'> <label className='col-8 '> Service Provider</label></div>
                                <input type='text' className='col-8 col-sm-8' name='receiver' value={receiver} onChange={e => setreceiver(e.target.value)} />
                            </div>


                            <div className='mail-inputs col-12 inline'>
                                <div className='col-3 mail-label'> <label className='col-8'>File Name </label></div>
                                <input type='text' className='col-8 col-sm-8' name='receiver' value={receiver} onChange={e => setreceiver(e.target.value)} />
                            </div>



                            <div className='mail-inputs col-12 inline'>
                                <div className='col-3 mail-label'> <label className='col-8'> To Mail Id(s)</label></div>
                                <input type='text' className='col-8 col-sm-8' name='to' value={to} onChange={e => setto(e.target.value)} />
                            </div>

                            <div className='mail-inputs col-12 inline'>
                                <div className='col-3 mail-label'> <label className='col-8' >CC Mail Id(s) </label></div>
                                <input style={{ marginBottom: "3vh" }} type='text' className='col-8 col-sm-8' name='receiver' value={receiver} onChange={e => setreceiver(e.target.value)} />
                            </div>
                            <div className='mail-inputs col-12 inline'>
                                <div className='col-3 mail-label'><label className='col-8' >Subject </label></div>
                                <input style={{ marginBottom: "3vh" }} type='text' className='col-8 col-sm-8' name='subject' value={subject} onChange={e => setsubject(e.target.value)} />
                            </div>
                            <div className='mail-inputs col-12 inline'>
                                <div className='col-3 mail-label'><label className='col-8' >Content </label></div>
                                <textarea className='col-8 col-sm-8' name='content' value={content} onChange={e => setcontent(e.target.value)} />
                            </div>
                            <div className='mail-inputs col-12 inline signature'>
                                <div className='col-3 mail-label'><label className='col-8' >Signature </label></div>
                                <textarea style={{marginTop:"2px"}} rows={5} className='col-8 col-sm-5' name='signature' value={signature} onChange={e => setsignature(e.target.value)} />
                                <div className='col-4 mail-btns'>
                                    <button className='btn2  mail-btn' onClick={sendMail}>Send MIS Report</button>
                                    <button className='btn2 mail-btn'>Close</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <div className='btn'>
          <Link className="btn2 col-2 col-sm-1"  to=""><i className="fa fa-save" aria-hidden="true"></i> Submit</Link>
          <Link className="btn2 col-2 col-sm-1" to=""><i className="fa fa-edit"></i> Edit</Link>
          <Link className="btn2 col-2 col-sm-1" to=""><i className="fa fa-save"></i> Save</Link>
          <Link className="btn2 col-2 col-sm-1" to=""> Delete</Link>
          <Link className="btn2 col-2 col-sm-1" to=""><i className="fa fa-close"></i> Cancel</Link>
          <Link className="btn2 col-2 col-sm-1" to=""> <i className="fa fa-sign-out" aria-hidden="true"></i> Close</Link>
          <Link className="btn2 col-2 col-sm-1" to=""><i className="fa fa-search" aria-hidden="true"></i> Search</Link>
        </div>
        </div>
    )
}

export default InwardMail