import { ChangeEvent, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import AccountHeader from '../accountHeader'
import VendorHeader from './vendorHeader'
import AccountService from '../../../services/accountData.service'
import Select from 'react-select';
import MasterDataService from '../../../services/masterData.service'
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory from 'react-bootstrap-table2-filter';
import { read, utils, writeFile } from 'xlsx';

const customStyles = {
  option: (provided: any, { isFocused, isSelected }: any) => ({
    ...provided,
    padding: 5,
    zIndex: 1,
    background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected ? 'hsl(0deg 0% 20% / 65%)' : undefined,
  }),
  control: (prev: any) => ({
    ...prev,
    padding: 0,
    width: "100%"

  }),
  menu: (base: any, { isFocused, isSelected }: any) => ({
    ...base,
    zIndex: 100,
    background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected ? 'hsl(0deg 0% 20% / 65%)' : undefined,
  }),
}
const VendorZone = () => {


  const [productName, setproductName] = useState("");
  const [productId, setproductId] = useState(0);
  const [zoneName, setzoneName] = useState("");
  const [shipmentmode, setshipmentmode] = useState([]);
  const [modeName, setmodeName] = useState('')
  const [modeId, setmodeId] = useState(0);
  const [serviceProvider, setserviceProvider] = useState('');
  const [serviceProviderId, setserviceProviderId] = useState(0);
  const [serviceProviderList, setserviceProviderList] = useState([]);
  const [zoneZipCodes, setZoneZipCodes] = useState<string>('');
  

  const { state } = useLocation();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRecords, setSelectedRecords] = useState();
  const [allZonepincode, setallZonepincode] = useState([])
  const [importedZonepincode, setimportedZonepincode] = useState<any | []>([])
  
  
   //Table Start
   interface selectRowType {
    mode: any;
    clickToSelect: boolean;  
    style: any;
    onSelect: any;
}
const selectRow: selectRowType = {
    mode: "radio",
    clickToSelect: true,
    style: { backgroundColor: '#c8e6c9' },
    onSelect: (row: any) => {
        setSelectedRecords(row.id);
    }
}
interface Article {
    options: any,
    currSizePerPage: any,
    onSizePerPageChange: any,
}
const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
}: Article) => (
    <div className="btn-group" role="group">
        {
            options.map((option: any) => {
                const isSelect = currSizePerPage === `${option.page}`;
                return (
                    <button
                        key={option.text}
                        type="button"
                        onClick={() => onSizePerPageChange(option.page)}
                        className={`btn ${isSelect ? 'btn-secondary' : 'btn-warning'}`}
                    >
                        {option.text}
                    </button>
                );
            })
        }
    </div>
);

interface optionsType {
    sizePerPageRenderer: any
}
const options: optionsType = {
    sizePerPageRenderer
};

const pagination = paginationFactory(options);
const columns: ColumnDescription<selectRowType>[] = [
    { dataField: 'id', formatter: (cell, row, rowIndex, formatExtraData) => {  return rowIndex + 1;  }, text: 'id', align: 'left', headerClasses: 'left-aligned-header' },
    { dataField: 'pincode', text: 'Pincode', align: 'left', headerClasses: 'left-aligned-header' },
    { dataField: 'serviceTat', text: 'Service TAT', align: 'left', headerClasses: 'left-aligned-header' }  
];
//Table End

useEffect(()=>{
if(state){
  getZonepincodes()
}},[])

const getZonepincodes = async () => {
    await AccountService.getZonePincode(modeId, serviceProviderId).then(
        (data) => {
            setallZonepincode(data);
        }
    )
}
  const HandleSubmit = () => {
    AccountService.postZone(zoneName, serviceProviderId, allZonepincode, modeId).then(
      (data) => {
        if (data) {
          navigate('../vendorzonelist');
        }
      }
    ).catch((error) => {
      console.log(error);
    })
  }

  // edit the selected data
  const HandleEdit = () => {
    AccountService.putZone( zoneName, serviceProviderId, allZonepincode, modeId, state).then(
      (data) => {
        if (data) {
          navigate('../vendorzonelist');
        }
      }
    ).catch((error) => {
      console.log(error);
    })
  }
  //  get Zone by Id
  const getZoneById = async () => {
    if (state) {
      try {
        const data = await AccountService.getZoneById(state);
        if (data) {
          setproductName(data.productName);
          setserviceProviderId(data.serviceProviderId);
          setmodeId(data.shipmentModeId);
          setproductId(data.productId);
          setserviceProvider(data.serviceProviderName);
          setmodeName(data.shipmentModeName);
        } else {
          setproductName('');
          setzoneName('');
        }
      } catch (error) {
        console.error(error);
      }
    }
  };
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })

  const HandleDelete = () => {
    if (state !== null)

      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          AccountService.deleteZone(state).then(
            (data) => {
              if (data.statuscode.message === '200') {
              }
            }
          ).catch((error) => {
            console.log(error);
            console.log("server responding");

          })
          Swal.fire({
            title: "Deleted",
            icon: 'success',
            text: "Zone has been deleted",
          }).then(function () {
            navigate('../vendorzonelist');
          })
        } else if (

          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            {
              title: "Cancelled",
              icon: 'error',
              text: "Zone is safe :)",
            }).then(function () {
              navigate('../vendorzonelist');
            }
            )
        }
      })
  }

  const HandleCancel = () => {
    setzoneName("");
  }
  useEffect(() => {
    getZondedetails()
    getShipmentMode()
    getServiceProvider()
  }, [])
  // get shipment mode option 
  const getShipmentMode = () => {
    MasterDataService.GetshipmentMode().then(
      async (data) => {
        if (data) {
          const options = data.map((d: any) => ({
            "value": d.id,
            "label": d.modeName
          }))
          await setshipmentmode(options)
          if (state === null) {
            if (data[0]) {
              await setmodeId(data[0]['id'])
              await setmodeName(data[0]['modeName'])
            }
          } else {
            let modesNames = data.find((item: any) => {
              return item.id === modeId
            });
            if (modesNames !== undefined) {
              setmodeName(modesNames.modeName)
            }
          }
        }
      }
    );
  }
  const getZondedetails = () => {
    if(state) {
      AccountService.getZoneById(state).then(
        (data) => {
          if(data){
            setzoneName(data.zoneName)
            setmodeId(data.shipmentModeId)
            setmodeName(data.shipmentModeName)
            setserviceProviderId(data.serviceProviderId)
            setserviceProvider(data.serviceProviderName)
            setallZonepincode(data.zonePincodes)
            setimportedZonepincode(data.zonePincodes);
          }
        }
      )
    }
  }
  const handleModeChange = (e: any) => {
    setmodeId(e.value);
    setmodeName(e.label)
  }
  const getServiceProvider = () => {
    MasterDataService.GetserviceProvider().then(
      async (data) => {
        if (data) {
          const options = data.map((d: any) => ({
            "value": d.id,
            "label": d.serviceProviderName
          }))
          await setserviceProviderList(options)
          if (state === null) {
            if (data[0]) {
              await setserviceProviderId(data[0]['id'])
              await setserviceProvider(data[0]['serviceProviderName'])
            }
          } else {
            let serviceProviderLists = data.find((item: any) => {
              return item.id === serviceProviderId
            });
            if (serviceProviderLists !== undefined) {
              setserviceProvider(serviceProviderLists.serviceProviderName)
            }
          }
        }
      }
    )
  }
  
  const handleServiceProvider = (e: any) => {
    setserviceProviderId(e.value);
    setserviceProvider(e.label)
  }
// setimportedZonepincode(filteredData)
  //------------excel import --------------
  
  
  const getUnique = (newArr :any , index:any) => {

    const unique = newArr.map((e:any) => e[index]).map((e:any, i:any, final:any) => final.indexOf(e) === i && i).filter((e:any) => newArr[e]).map((e:any) => newArr[e]); return unique
  }

     const handleImport = (event: any) => {
    if (!event.target.files) return;
    const files = event.target.files;
    if (files.length) {
        const file = files[0];
        const reader = new FileReader();
        reader.onload = (event:any) => {
            const wb = read(event.target.result);
            const sheets = wb.SheetNames;
            if (sheets.length) {
                const rows: any  = utils.sheet_to_json(wb.Sheets[sheets[0]]);
               var newArr :any = [...allZonepincode, ...rows];
              const uniqueArr =  getUnique(newArr, 'pincode') ;
              
              // const uniqueData = newArr.filter((row:any, index:any, self:any) => {
              //   const keys = Object.keys(row);
              //   return index === self.findIndex((item:any) => keys.every((key) => item[key] === row[key]));
              // });
                setallZonepincode(uniqueArr);
                  setimportedZonepincode(uniqueArr)
            }
        }
        reader.readAsArrayBuffer(file);

    }
}
  //-------------excel import end here ---------
  
useEffect(() => {
setFilterdData();
},[searchQuery])

const setFilterdData = async () => {
  await setimportedZonepincode(Array.isArray(allZonepincode) ? allZonepincode.filter((searchRecords) => {
    const values = Object.values(searchRecords);
    return values.some((value: unknown): value is string =>
    typeof value === 'string' ? value.toLowerCase().includes(searchQuery.toLowerCase())
    : typeof value === 'number' ? value.toString().includes(searchQuery.toLowerCase()) : false
    )
  }): [])
}
  return (
    <>
      <AccountHeader />

      <div style={{ margin: "0px" }}>
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}></div>
        <div className='container col-10' style={{ paddingLeft: "0px" }} >
          <div className="themeColor width" ><p style={{ marginLeft: "10px" }}>Zone Details </p></div>
          <VendorHeader />
          
          <div className='col-12 inline'>
          <div className='col-6'>
          <div className=' col-sm-12 inputs' style={{ marginTop: "10px" }}>
            <label className='col-4 '><b>Zone Name</b></label>
            <input type='text' className='col-8 ' value={zoneName} name='zoneName' onChange={e => setzoneName(e.target.value)} />
          </div>
          
          <div className=' col-sm-12 inputs' style={{ marginTop: "10px" }}>
            <label className='col-4'><b>Mode Name</b></label>
            <Select
              name="modeId"
              onChange={handleModeChange}
              options={shipmentmode}
              className='col-8'
              styles={customStyles}
              value={{ value: modeId, label: modeName }}
            />
          </div>
          <div className=' col-sm-12 inputs' style={{ marginTop: "10px" }}>
            <label className='col-4'><b>Service Provider</b></label>
            <Select
              name="modeId"
              onChange={handleServiceProvider}
              options={serviceProviderList}
              className='col-8 select'
              styles={customStyles}
              value={{ value: serviceProviderId, label: serviceProvider }}
            />
          </div>
          </div>
          <div className='col-6'>
          <div className='col-12' style={{ display: "flex", flexDirection: "row", width: "100%", height: "50px", justifyContent:"space-between", alignItems: "center" }}>
     <div className='col-6'> 
     <input type="file" name="file" className="custom-file-input" id="inputGroupFile" required onChange= {handleImport} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/> <label className="custom-file-label" htmlFor="inputGroupFile">Choose file</label>
     {/* <button className='inwordbtn' onClick={uploadPincode}><i className="fa fa-upload"> </i> Upload Pincode </button> */}
     </div>
                            <input
                                className='searchbar col-3'
                                type='text'
                                value={searchQuery}
                                placeholder='Search Here ...'
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </div>
          <BootstrapTable rowStyle={{ fontSize: '15px', padding: '0px', whiteSpace: 'nowrap', width: '100%', textAlign: "center" }}
                            keyField='id'
                            striped
                            hover
                            condensed
                            data={importedZonepincode}
                            columns={columns}
                            pagination={pagination}
                            selectRow={selectRow}
                            filter={filterFactory()}
                            filterPosition="top"
                        />
          </div>
          </div>
        </div>
        <div className='btn d-flex justify-content-center'>
          {(state === null) ? <Link className="btn2 col-2 col-sm-1"
            onClick={HandleSubmit} to="../vendorzonelist"> <i className="fa fa-plus" aria-hidden="true"> </i>  Submit </Link> : <>
            <Link className="btn2 col-2 col-sm-1" onClick={HandleEdit} to="../vendorzonelist"> <i className="fa fa-edit"> </i>   Update </Link>
            <button className="btn2 col-2 col-sm-1" onClick={HandleDelete} > <i className="fa fa-trash"> </i>   Delete</button> </>}
          <button className="btn2 col-2 col-sm-1" onClick={HandleCancel}><i className="fa fa-close"></i>   Cancel</button>
          <Link className="btn2 col-2 col-sm-1" to="/vendorzonelist"> <i className="fa fa-sign-out" aria-hidden="true"></i>   Close</Link>
        </div>
      </div>
    </>
  )
}

export default VendorZone
