// import "./styles.css";
import { useState, useCallback } from "react";
import { BarChart, Bar, Cell } from "recharts";


export default function OutwardStatusBarChart(props:any) {
  const [activeIndex, setActiveIndex] = useState(0);
  const data = props.dataforBarChart.map((d:any) => ({
    "name" : d.name,
    "value" : d.value
  }))
  const activeItem = data ? data[activeIndex] : [];
  const handleClick = useCallback(
    (entry: any, index: number) => {
      setActiveIndex(index);
    },
    [activeIndex]
  );

  return (
    <div>
      <p className="dash-head1">Inward Status Of {props.todaysDate} </p>
      <BarChart width={600} height={280} data={data}>
        <Bar dataKey="value" onClick={handleClick}>
          {data.map((entry:any, index:Number) => (
            <Cell
              cursor="pointer"
              fill={index === activeIndex ? "rgb(94, 83, 83)" : "#db1c29"}
              key={`cell-${index}`}
            />
          ))}
        </Bar>
      </BarChart>
      <p className="content dash-head1"> { activeItem ? `${activeItem.name}: ${activeItem.value}` : ''}</p>
    </div>
  );
}
