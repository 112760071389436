import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const ReportContainerHeader = () => {

    const [classhideHeader, setclasshideHeader] = useState('');
    const hideHeader = () => {
        setclasshideHeader("hideHeader");
    }
    return (
        <div className='row col-12' >
            <ul className="nav nav-pills col-12 " id="pills-tab" role="tablist">
                <Link to="/inwardreport" className="navbar-item inwordbtn" >Inward Reports</Link>
                <Link to="/outwardreport" className="navbar-item inwordbtn" >Outwards</Link>
                <Link to="/misreport" className="navbar-item inwordbtn" >MIS Report</Link>
            </ul>
            {/* <ul>
                    <a className={classhideHeader} onClick={hideHeader}> Skip Header</a>
                </ul> */}

        </div>
    )
}

export default ReportContainerHeader