import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

const upload_Path = process.env.REACT_APP_BASE;


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const OutwardDrs = () => {
  const pdfUrl = upload_Path + 'mailroom/MailRoomPresentation.pdf';

  const numPages = 30; // Update this with the actual number of pages in your PDF

  const renderPages = () => {
    const pages = [];
    for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
      pages.push(
        <div key={pageNumber} className="pdf-page">
          <Page pageNumber={pageNumber} />
        </div>
      );
    }
    return pages;
  };

  return (
    <div className="pdf-container text-center" style={{textAlign: 'center'}}>
      <Document file={pdfUrl} >
        {renderPages()}
      </Document>
    </div>
  );
};

export default OutwardDrs;