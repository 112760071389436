import { useEffect, useState } from 'react'
import MasterHeader from '../masterHeader'
import locationService from '../../../services/locationData.service'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import LocationHeader from './locationHeader'
const Country = () => {


  const [countryName, setcountryName] = useState("");
  const [countryCode, setcountryCode] = useState("");
  const { state } = useLocation();
  const navigate = useNavigate();
  const HandleSubmit = () => {
    locationService.postCountry(countryName, countryCode).then(
      (data) => {
        if (data) {
          navigate('../countrylist');
        }
      }
    ).catch((error) => {
      console.log(error);
    })
  }
  // edit the selected data
  const HandleEdit = () => {
    locationService.putCountry(countryName, countryCode, state).then(
      (data) => {
        if (data) {
          navigate('../countrylist');
        }
      }
    ).catch((error) => {
      console.log(error);
    })
  }
  //  get Country by Id
  const getCountryById = async () => {
    if (state) {
      try {
        const data = await locationService.getCountryById(state);
        if (data) {
          setcountryName(data.countryName);
          setcountryCode(data.countryCode);
        } else {
          setcountryName('');
          setcountryCode('');
        }
      } catch (error) {
        console.error(error);
      }
    }
  };
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })

  const HandleDelete = () => {
    if (state !== null)

      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          locationService.deleteCountry(state).then(
            (data) => {
              if (data.statuscode.message === '200') {
              }
            }
          ).catch((error) => {
            console.log(error);
            console.log("server responding");

          })
          Swal.fire({
            title: "Deleted",
            icon: 'success',
            text: "Country has been deleted",
          }).then(function () {
            navigate('/countrylist');
          })
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            {
              title: "Cancelled",
              icon: 'error',
              text: "Country is safe :)",
            }).then(function () {
              navigate('/country');
            }
            )
        }
      })
  }

  const HandleCancel = () => {
    setcountryName("");
    setcountryCode("");
  }
  useEffect(() => {
    if (state !== null) {
      getCountryById();
    }

  }, [])



  return (
    <>
      <MasterHeader />

      <div style={{ margin: "0px" }}>
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}></div>
        <div className='container col-10' style={{ paddingLeft: "0px" }} >
          <div className="themeColor width" ><p style={{ marginLeft: "10px" }}>Country Details </p></div>
          <LocationHeader />
          <div className=' col-sm-12 inputs' style={{ marginTop: "10px" }}>
            <label className='col-6 col-sm-2'><b>Country Name</b></label>
            <input type='text' className='col-8 col-sm-4' value={countryName} name='countryName' onChange={e => setcountryName(e.target.value)} />
          </div>

          <div className=' col-sm-12 inputs' style={{ marginTop: "10px" }}>
            <label className='col-6 col-sm-2'><b> Country Code</b></label>
            <input type='text' className='col-8 col-sm-4' value={countryCode} name='countryCode' onChange={e => setcountryCode(e.target.value)} />
          </div>
        </div>
        <div className='btn d-flex justify-content-center'>
          {(state === null) ? <Link className="btn2 col-2 col-sm-1" onClick={HandleSubmit} to="../countrylist"> <i className="fa fa-plus" aria-hidden="true"> </i>  Submit </Link> : <>
            <Link className="btn2 col-2 col-sm-1" onClick={HandleEdit} to="../countrylist"> <i className="fa fa-edit"> </i>   Update </Link>
            <button className="btn2 col-2 col-sm-1" onClick={HandleDelete} > <i className="fa fa-trash"> </i>   Delete</button> </>}
          <button className="btn2 col-2 col-sm-1" onClick={HandleCancel}><i className="fa fa-close"></i>   Cancel</button>
          <Link className="btn2 col-2 col-sm-1" to="/countrylist"> <i className="fa fa-sign-out" aria-hidden="true"></i>   Close</Link>
        </div>
      </div>
    </>
  )
}

export default Country
