import  { useState, useEffect } from 'react';
import moment from 'moment';
import InwardContainerHeader from './InwardContainerHeader';
import MasterDataService from '../../services/masterData.service';
import '../inward/inwardlog.css';
import InwardLogService from '../../services/inwardlog.service';
import {useLocation, useNavigate, Link } from 'react-router-dom';
import Select from 'react-select';
import Swal from 'sweetalert2';

const InwardLog = () => {
  const navigate = useNavigate();
  const [shipmentmode, setshipmentmode] = useState([])
  const [modeName, setmodeName] = useState('');
  const [modeId, setmodeId] = useState(0);
  const [inwardDate, setinwardDate] = useState((moment(new Date())).format('YYYY-MM-DD'));
  const [inwardTime, setinwardTime] = useState((moment(new Date()).utcOffset('+0530')).format('HH:mm'));
  const [serviceProvider, setserviceProvider] = useState('');
  const [serviceProviderId, setserviceProviderId] = useState(0);
  const [serviceProviderList, setserviceProviderList] = useState([]);
  const [noOfShipment, setnoOfShipment] = useState('');
  const [deliveryStaff, setdeliveryStaff] = useState('');
  const [logRemarks, setlogRemarks] = useState('');
  const [firstCall, setFirstCall] = useState(false);
  
  const { state } = useLocation();   

  const customStyles = {
    option: (provided: any, { isFocused, isSelected }: any) => ({
      ...provided,
      padding: 5,
      zIndex: 1,
      background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected ? 'hsl(0deg 0% 20% / 65%)' : undefined,
    }),
    control: (prev: any) => ({
      ...prev,
      // none of react-select's styles are passed to <Control />
      padding: 0,
      width: "100%",
      paddingLeft:"0px",paddingRight:"0px"
  
    }),
    menu: (base: any, { isFocused, isSelected }: any) => ({
      ...base,
      zIndex: 100,
      background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected ? 'hsl(0deg 0% 20% / 65%)' : undefined,
    }),
  }
  useEffect(() => {
    if(state){
      getInwardLog()
    }
    getShipmentMode();
    getServiceProvider();
  }, [firstCall])
  const getInwardLog =async () => {
    if(state){            
      try {
        await InwardLogService.getLogById(state).then(
         async (data) => {
          if(data){
            setmodeId(data.modeName)
            setserviceProviderId(data.serviceProvider)
            setinwardDate(data.inwardDate)
            setinwardTime(data.inwardTime)
            setnoOfShipment(data.noOfShipment)
            setlogRemarks(data.logRemarks)
            setdeliveryStaff(data.deliveryStaff)
            setFirstCall(true)
          }   else {
            setFirstCall(true)
          }          
         }
        );
        
      } catch (error) {     
        console.error(error);
      }
    }
  }
  const getShipmentMode = () => {
    MasterDataService.GetshipmentMode().then(
        async (data) => {
          if(data) {            
          const options = data.map((d: any) => ({
            "value": d.id,
            "label": d.modeName
          }))
          await setshipmentmode(options)
          if (state === null) {
            if (data[0]) {
              await setmodeId(data[0]['id'])
              await setmodeName(data[0]['modeName'])
            }
          } else {
            let modesNames = data.find((item: any) => {
              return item.id === modeId
            });
            if (modesNames !== undefined) {
              setmodeName(modesNames.modeName)
            }
          }
        }
        }
    );
  }
  const getServiceProvider = () => {
    MasterDataService.GetserviceProvider().then(
      async (data) => {
        if(data) {            
        const options = data.map((d: any) => ({
          "value": d.id,
          "label": d.serviceProviderName
        }))
        await setserviceProviderList(options)
        if (state === null) {
          if (data[0]) {
            await setserviceProviderId(data[0]['id'])
            await setserviceProvider(data[0]['serviceProviderName'])
          }
        } else {
          let serviceProviderLists = data.find((item: any) => {
            return item.id === serviceProviderId
          });
          if (serviceProviderLists !== undefined) {
            setserviceProvider(serviceProviderLists.serviceProviderName)
          }
        }
      }
      }
    )
  }
  const handleModeChange = (e:any) => {
setmodeId(e.value);
setmodeName(e.label)
  }
  const handleServiceProvider = (e:any) => {
    setserviceProviderId(e.value);
    setserviceProvider(e.label)
  }
  const inwardLogSubmit = () => {
    let errorMessage = ""
    if((noOfShipment !== "") && (deliveryStaff !== "")) {

    InwardLogService.inwardlogCreate(
      modeId,
      inwardDate,
      inwardTime,
      serviceProviderId,
      noOfShipment,
      deliveryStaff,
      logRemarks
    ).then(
      (data) => {
        if (data) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: "Inward Log Created Successfully",
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              title: 'swal-fire-alert', // Add your custom CSS class here
            },
          }).then(function () {
            navigate('../inwardloglist');
          });
        }
      }
    ).catch((error) => {
      console.log(error)
    })
  } else {
    if ((noOfShipment === "") && (deliveryStaff === "")) {
      errorMessage = "Please Enter No of Pcs. Received And Delivery Staff Details";
    } else if (deliveryStaff === "") {
      errorMessage = "Please Enter Delivery Staff Name";
    } else if (noOfShipment === "") {
      errorMessage = "Please Enter No of Pcs. Received";
    }
    
    Swal.fire({
      position: 'center',
      icon: 'warning',
      title: errorMessage,
      showConfirmButton: false,
      timer: 1500,
      customClass: {
        title: 'swal-fire-alert', // Add your custom CSS class here
      },
    });
  }
  }
  const handleEdit = () => {
    let errorMessage = ""
    if((noOfShipment !== "") && (deliveryStaff !== "")) {
    InwardLogService.inwardlogUpdate(  modeId,  inwardDate, inwardTime,  serviceProviderId, noOfShipment, deliveryStaff, logRemarks, state
    ).then(
      (data) => {
        if (data) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: "Inward Log "+ data.batchNo+" Updated Successfully",
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              title: 'swal-fire-alert', // Add your custom CSS class here
            },
          }).then(function () {
            navigate('../inwardloglist');
          });
        }
      }
    ).catch((error) => {
      console.log(error)
    })
  } else {
    if ((noOfShipment === "") && (deliveryStaff === "")) {
      errorMessage = "Please Enter No of Pcs. Received And Delivery Staff Details";
    } else if (deliveryStaff === "") {
      errorMessage = "Please Enter Delivery Staff Name";
    } else if (noOfShipment === "") {
      errorMessage = "Please Enter No of Pcs. Received";
    }
    
    Swal.fire({
      position: 'center',
      icon: 'warning',
      title: errorMessage,
      showConfirmButton: false,
      timer: 1500,
      customClass: {
        title: 'swal-fire-alert', // Add your custom CSS class here
      },
    });
  }
  }
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })
  const handleDelete = () => {
    if (state !== null)
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          InwardLogService.deleteinwardLog(state).then(
            (data) => {              
              if(data){
                navigate("../inwardloglist");
              }
            }
          ).catch((error) => {
            console.log(error);
            console.log("server responding");

          })
          Swal.fire({
            title: "Deleted",
            icon: 'success',
            text: "Inward Log has been deleted",
          }).then(function () {
            navigate('../inwardloglist');
          })
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            {
              title: "Cancelled",
              icon: 'error',
              text: "Inward Log is safe :)",
            }).then(function () {
              window.location.reload()
            }
            )
        }
      })
  }
  return (
    <>
      <InwardContainerHeader />
      <div style={{ margin: "0px" }}>
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}></div>
        <div className='container col-10' style={{ paddingLeft: "0px" }} >
          <div className="themeColor width" ><p style={{ marginLeft: "10px" }}> Inward Log </p></div>
      <div className='col-10' >      
        <div className='col-sm-12 inputs'>
          <label className='col-6 col-sm-2'> Date&Time</label>
          <input type='date' className='col-5 col-sm-2 zeroPadding' value={inwardDate} max={moment().format("YYYY-MM-DD")} name='inwardDate' onChange={e => setinwardDate(e.target.value)} />
          <input className='col-3 col-sm-2 zeroPadding' value={inwardTime} type='time' name='inwardTime' onChange={e => setinwardTime(e.target.value)} />


        </div>
        <div className='col-sm-12 inputs'>
          <label className='col-6 col-sm-2'> Mode</label>
          <div className='col-6 col-sm-4 select' style={{paddingLeft:"0px",paddingRight:"0px"}}>
          <Select
                      name="modeId"
                      onChange={handleModeChange}
                      options={shipmentmode}
                      styles={customStyles}
                      value={{ value: modeId, label: modeName }}
                    />
            </div>
       </div>

        <div className=' col-sm-12 inputs'>
          <label className='col-6 col-sm-2'> Service Provider</label>
          <div className='col-6 col-sm-4 select' style={{paddingLeft:"0px",paddingRight:"0px"}}>
          <Select     name="serviceProviderId"
                      onChange={handleServiceProvider}
                      options={serviceProviderList}
                      styles={customStyles}
                      value={{ value: serviceProviderId, label: serviceProvider }}
                    />
                    </div>
       </div>


        <div className=' col-sm-12 inputs'>
          <label className='col-6 col-sm-2'> No of Shipment </label>
          <input type='text' className='col-8 col-sm-4 zeroPadding text-left' value={noOfShipment} name='noOfShipment' onChange={(e:any )=> { if (/^\d*$/.test(e.target.value)) { setnoOfShipment(e.target.value)}}} />
        </div>



        <div className=' col-sm-12 inputs'>
          <label className='col-6 col-sm-2'> Delivery Staff </label>
          <input type='text' className='col-8 col-sm-4 zeroPadding' value={deliveryStaff} name='deliveryStaff' onChange={e => setdeliveryStaff(e.target.value)} />
        </div>

        <div className=' col-sm-12 inputs'>
          <label className='col-6 col-sm-2' >Log Remark </label>
          <input style={{ marginBottom: "3vh" }} type='text' className='col-8 col-sm-4 zeroPadding' value={logRemarks} name='logRemarks' onChange={e => setlogRemarks(e.target.value)} />
        </div>
      </div>
      <div>
        <div className='btn d-flex justify-content-center'>        
        {(state === null) ? <button className="btn2 col-2 col-sm-1" type="button" onClick={inwardLogSubmit}><i className="fa fa-save"></i> Save</button> : <>
        <button className="btn2 col-2 col-sm-1"  type="button" onClick={handleEdit}  ><i className="fa fa-edit"></i> Update </button>
          <button className="btn2 col-2 col-sm-1"  type="button" onClick={handleDelete}> <i className="fa fa-trash"></i> Delete</button></> }
          <button className="btn2 col-2 col-sm-1"  type="button"> <i className="fa fa-close" ></i> Clear</button>
          <Link className="btn2 col-2 col-sm-1" to="../inwardloglist" type='button'>  <i className="fa fa-sign-out"> </i> Close </Link>
       
        </div>
      </div>
      </div>
      </div>
    </>
  )
}

export default InwardLog