import axios from "axios";

const API_URL = process.env.REACT_APP_BASE_URL_API;
const token = `Bearer ` + localStorage.getItem('token');
const createdBy = localStorage.getItem('userId');
const updatedBy = localStorage.getItem('userId');
const mailRoomId = localStorage.getItem('mailRoomId');
const branch = (localStorage.userDetails) ? JSON.parse(localStorage.userDetails).branch : "";

const config = {
    headers: {
        'Authorization': token,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
    }
};
// --------------------Product -------------
const getProduct = async () => {
    return axios.get(API_URL + "product/", config).then(
        (response) => {
            return response.data.message
        })
        .catch(error => console.log(error))
}
const getProductbyServiceProvider = async (serviceProviderId: number, branch :Number) => {
    const bodyParamter = {
        serviceProviderId,
        branch :branch,
        mailRoomId
    };
    return axios.post(API_URL + "product/getbyserviceprovider", bodyParamter, config).then(
        (response) => {
            if (response.data.statuscode === "200") {
                return response.data.message
            }
        }
    ).catch(error => { return "" })

}
const deleteProduct = async (id: Number) => {
    return axios.delete(API_URL + "product/" + id, config).then(
        (response) => {
            return response.data.message
        })
        .catch(error => console.log(error))

}

const postProduct = async (productName: String, serviceProviderId: number, shipmentModeId: number, zoneId : string) => {
    const bodyParamter = {
        productName,
        serviceProviderId,
        shipmentModeId,
        tCode: Date.now().toString(),
        zoneId,
        productStatus: 1,
        mailRoomId,
        branch,
        createdBy
    };
    return axios.post(API_URL + "product/register", bodyParamter, config).then(
        (response) => {
            return response.data.message
        })
        .catch(error => console.log(error))

}

const putProduct = async (productName: String, serviceProviderId: number, shipmentModeId: number, zoneId : string, id: any) => {
    const bodyParamter = {
        productName,
        serviceProviderId,
        shipmentModeId,
        zoneId,
        tCode: Date.now().toString(),
        updatedBy
    };
    return axios.put(API_URL + "product/" + id, bodyParamter, config).then(
        (response) => {
            return response.data.message
        })
        .catch(error => console.log(error))

}

const getProductById = async (id: any) => {

    return axios.get(API_URL + "product/" + id, config).then(
        (response) => {
            return response.data.message
        }
    ).catch(error => console.log(error))


}
// -------------------------Product End ----------------

// --------------------Zone -------------
const getZone = async () => {
    const bodyParamter = {
mailRoomId,
branch
    }
    return axios.post(API_URL + "vendorzone/getallbybranch", bodyParamter, config).then(
        (response) => {
            return response.data.message
        })
        .catch(error => console.log(error))
}
const getzonebyproduct = async (productId: number) => {
    const bodyParamter = {
        productId,
        branch,
        mailRoomId

    };
    return axios.post(API_URL + "vendorzone/getbyproduct/", bodyParamter, config).then(
        (response) => {
            if (response.data.statuscode === "200") {
                return response.data.message
            }
        }
    ).catch(error => { return "" })

}
const getzonebyServiceProvider = async (serviceProviderId: number) => {
    const bodyParamter = {
        serviceProviderId,
        branch,
        mailRoomId

    };
    return axios.post(API_URL + "vendorzone/getbyserviceprovider/", bodyParamter, config).then(
        (response) => {
            if (response.data.statuscode === "200") {
                return response.data.message
            }
        }
    ).catch(error => { return "" })

}
const deleteZone = async (id: Number) => {
    return axios.delete(API_URL + "vendorzone/" + id, config).then(
        (response) => {
            return response.data.message
        })
        .catch(error => console.log(error))

}

const postZone = async (zoneName: string, serviceProviderId: number, zoneZipCodes: any, shipmentModeId: number) => {
    const bodyParamter = {
        zoneName,
        serviceProviderId,
        shipmentModeId,
        zoneStatus: 1,
        mailRoomId,
        branch,
        createdBy

    };
    return axios.post(API_URL + "vendorzone/register", bodyParamter, config).then(
        async (response) => {
if(response.data.statuscode ==="200"){
  const  dataResponse = response.data.message;
          const updatedzoneZipCodes = zoneZipCodes.map((values:any ) => ({...values, zoneId : dataResponse.id, zoneName :  dataResponse.zoneName, serviceProviderId : dataResponse.serviceProviderId, shipmentModeId: dataResponse.shipmentModeId,zoneStatus : 1, mailRoomId : dataResponse.mailRoomId, branch:  dataResponse.branch, createdBy:  dataResponse.createdBy}))

            const chunkSize = 100; // Set the desired chunk size for each iteration
                const totalChunks = Math.ceil(updatedzoneZipCodes.length / chunkSize);

                for (let i = 0; i < totalChunks; i++) {
                const startIndex = i * chunkSize;
                const endIndex = Math.min((i + 1) * chunkSize, updatedzoneZipCodes.length);
                const chunkData = updatedzoneZipCodes.slice(startIndex, endIndex);

                // Create a request payload with the current chunk data
                const requestData = {
                     value:chunkData,
                };
                try {
                    // Send the POST request to the server using Axios
                     await axios.post(API_URL + "vendorzone/createPincode/", requestData, config );
                    // Handle the response from the server if needed
                } catch (error) {
                    // Handle errors if the request fails
                    console.error(error);
                }
                }            

            return response.data.message
        }
        })
        .catch(error => console.log(error))

}

const putZone = async (zoneName: string, serviceProviderId: number, zoneZipCodes: any, shipmentModeId: number, id: any) => {
    const bodyParamter = {
        zoneName,
        serviceProviderId,
        shipmentModeId,
        zoneStatus: 1,
        updatedBy
    };
    return axios.put(API_URL + "vendorzone/" + id, bodyParamter, config).then(
        async (response) => {
            if(response.data.statuscode === "200"){
                // const  dataResponse = response.data.message;
                        const updatedzoneZipCodes = zoneZipCodes.map((values:any ) => ({...values, zoneId : id, zoneName :  zoneName, serviceProviderId : serviceProviderId, shipmentModeId: shipmentModeId,zoneStatus : 1, mailRoomId : mailRoomId, branch:  branch, updatedBy:  updatedBy}))
              
                          const chunkSize = 100; // Set the desired chunk size for each iteration
                              const totalChunks = Math.ceil(updatedzoneZipCodes.length / chunkSize);
              
                              for (let i = 0; i < totalChunks; i++) {
                              const startIndex = i * chunkSize;
                              const endIndex = Math.min((i + 1) * chunkSize, updatedzoneZipCodes.length);
                              const chunkData = updatedzoneZipCodes.slice(startIndex, endIndex);
              
                              // Create a request payload with the current chunk data
                              const requestData = {
                                   value:chunkData,
                              };
                              try {
                                  // Send the POST request to the server using Axios
                                   await axios.post(API_URL + "vendorzone/createPincode/", requestData, config );
                                  // Handle the response from the server if needed
                              } catch (error) {
                                  // Handle errors if the request fails
                                  console.error(error);
                              }
                              }            
              
                          return response.data.message
                      }  
        }).catch(error => console.log(error))

}

const getZoneById = async (id: any) => {

    return axios.get(API_URL + "vendorzone/" + id, config).then(
        (response) => {
            return response.data.message
        }
    ).catch(error => console.log(error))


}

// ---------------------postZonevalidate-----------------------------------------------------------------------------------------------------------------------


const postZonevalidate = async (zoneZipCodes: any, serviceProviderId:number,
    productId:number) => {
    const bodyParamter = {
        
        serviceProviderId,
        productId,
        zoneZipCodes,
        mailRoomId,
    branch

    };
    return axios.post(API_URL + "zone/validatezipcode", bodyParamter, config).then(
        (response) => {
            if (response.data.statuscode === "200") {
                return response.data.message
            }
        }
    ).catch(error => { return "" })

}
// -------------------------Zone End ----------------

// ---------------------postZonevalidate-----------------------------------------------------------------------------------------------------------------------


const postRateContract = async (id :Number | null, branch: number, serviceProviderId: number,shipmentModeId :Number, zoneId: number, productId: number,
    additionalWeight: number, additionalRate: number,surcharge: number, fuelSurcharge: number, loadingCharge: number, unloadingCharge: number, ratesData :any ) => {
    const bodyParamter = {
        id,
        serviceProviderId,
        zoneId,
        productId,
        shipmentModeId,
        additionalWeight, additionalRate,surcharge, fuelSurcharge, loadingCharge, unloadingCharge,
        extraServiceCharge :0,
        tCode: Date.now().toString(),
        rateContractStatus: 1,
        ratesData,
        branch,
        mailRoomId,
        createdBy
    };
    return axios.post(API_URL + "ratecontract/register/", bodyParamter, config).then(
        (response) => {
            if (response.data.statuscode === "200") {
                return response.data.message
            }
        }
    ).catch(error => { return "" })

}

const getZonePincode = (shipmentModeId : Number, serviceProviderId :Number) => {
    const bodyParamter = {
        mailRoomId,
        branch,
        serviceProviderId,
        shipmentModeId
    }
    return axios.post(API_URL + "ratecontract/getratecontract/", bodyParamter, config).then(
        (response) => {
            if (response.data.statuscode === "200") {
                return response.data.message
            }
        }
    ).catch(error => { return "" })
}

const getZoneByServiceProvider = (serviceProviderId :Number) => {
    const bodyParamter = {
        mailRoomId,
        branch,
        serviceProviderId
    }
    return axios.post(API_URL + "vendorzone/getbyserviceprovider/", bodyParamter, config).then(
        (response) => {
            if (response.data.statuscode === "200") {
                return response.data.message
            }
        }
    ).catch(error => { return "" })
}

const getPreviousRate = (branch :Number, serviceProviderId:Number, product:Number, zone:Number) => {
    const bodyParamter = {
        mailRoomId,
        branch,
        serviceProviderId,
        productId:product,
        zoneId:zone
    }
    return axios.post(API_URL + "ratecontract/getratecontract", bodyParamter, config).then(
        (response) => {
            if (response.data.statuscode === "200") {
                return response.data.message
            }
        }
    ).catch(error => { return "" })
}


const getRateContracts = () => { 
    const bodyParamter = {
        mailRoomId,
        branch
    }
    return axios.post(API_URL + "ratecontract/", bodyParamter, config).then(
        (response) => {
            if (response.data.statuscode === "200") {
                return response.data.message
            }
        }
    ).catch(error => { return "" })   
}

const getRateContractById = (id: Number) =>{
    return axios.get(API_URL + "ratecontract/"+id, config).then(
        (response) => {
            if (response.data.statuscode === "200") {
                return response.data.message
            }
        }
    ).catch(error => { return "" })   
}

const getProductsByServiceProviderandZone = (serviceProviderId : Number, branch :Number, zoneId: any) => {
    const bodyParamter = {
        serviceProviderId,
        zoneId,
        mailRoomId,
        branch
    }
    return axios.post(API_URL + "product/getbyzonenadserviceprovider", bodyParamter, config).then(
        (response) => {
            if (response.data.statuscode === "200") {
                return response.data.message
            }
        }
    ).catch(error => { return "" }) 
}
const getRateContractByProductandzones = (modeId :Number, serviceProviderId:Number, productId:Number, zoneId :Number) => {
    const bodyParamter = {
        serviceProviderId,
        modeId,
        productId, zoneId,
        mailRoomId,
        branch
    }

    return axios.post(API_URL + "ratecontract/getratesbyproductandzones", bodyParamter, config).then(
        (response) => {
            if (response.data.statuscode === "200") {
                return response.data.message
            }
        }
    ).catch(error => { return "" }) 
}

const AccountService = {
    getProduct,
    deleteProduct,
    postProduct,
    putProduct,
    getProductById,
    getProductbyServiceProvider,

    getZone,
    deleteZone,
    postZone,
    putZone,
    getZoneById,
    postZonevalidate,
    getzonebyproduct,

    postRateContract,
    getZonePincode,
    getZoneByServiceProvider,

    getPreviousRate,
    getRateContracts,
    getRateContractById,
    getzonebyServiceProvider,
    getProductsByServiceProviderandZone,

    getRateContractByProductandzones,

}

export default AccountService;