
import React, { useEffect, useState } from 'react'
import MasterHeader from './masterHeader'
import MasterDataService from '../../services/masterData.service'
import Footer from '../headerfooter/Footer';
import { Link } from 'react-router-dom';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import '../inward/inwardloglist.css';
function CourierList() {
 
    const [selectedRecords, setSelectedRecords] = useState();    
     const [allServiceProvider ,setallServiceProvider] =useState([]);
    
      //Table Start
    
    
    
      interface selectRowType {
        mode: any;
        clickToSelect: boolean;
        style: any,
        onSelect: any
      }
      const selectRow: selectRowType = {
        mode: "radio",
        clickToSelect: true,
        style: { backgroundColor: '#c8e6c9' },
        onSelect: (row: any) => {
          setSelectedRecords(row.id)
        }
      }
      interface Article {
        options: any,
        currSizePerPage: any,
        onSizePerPageChange: any,
      }
      const sizePerPageRenderer = ({
        options,
        currSizePerPage,
        onSizePerPageChange
      }: Article) => (
        <div className="btn-group" role="group">
          {
            options.map((option: any) => {
              const isSelect = currSizePerPage === `${option.page}`;
              return (
                <button
                  key={option.text}
                  type="button"
                  onClick={() => onSizePerPageChange(option.page)}
                  className={`btn ${isSelect ? 'btn-secondary' : 'btn-warning'}`}
                >
                  {option.text}
                </button>
              );
            })
          }
        </div>
      );
    
      interface optionsType {
        sizePerPageRenderer: any
      }
      const options: optionsType = {
        sizePerPageRenderer
      };
    
      const pagination = paginationFactory(options);
      const columns = [
    
        { dataField: 'Courier', text:'Courier' },
        
        
      ];
      //Table End

      const GetserviceProvider = async ()=>{
       await MasterDataService.GetserviceProvider().then(
        (data)=>{
            setallServiceProvider(data.message);
        }
       )
      }
      useEffect(()=>{
        GetserviceProvider();
      })
      return (
        <>
          {/* <Header /> */}
          {/* <InwardContainerHeader /> */}
          <MasterHeader />
    
    
          <div style={{ margin: "0px" }}>
            <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}>
              <div className="redline3 redline  " style={{ marginLeft: "16vh",width:"84%" }} ><p style={{ marginLeft: "10px" }}>Service Provider List </p></div>
    
              <div className="container col-10">
                    <BootstrapTable rowStyle={{ fontSize: '15px', padding: '0px', whiteSpace: 'nowrap', width: '100%',textAlign:"center" }} keyField='id' striped hover condensed data={allServiceProvider} columns={columns}
                  pagination={pagination}
                  selectRow={selectRow}
                />
    
              </div>
            </div>
          </div>
          <div className='btn fixedBottom'>
            <Link to="/Courier" className="btn2 col-2 col-sm-1"  > <i className="fa fa-plus" aria-hidden="true"></i>Add</Link>
            {/* <button className="btn2 col-2 col-sm-1" type="submit"><i className="fa fa-plus" aria-hidden="true"></i> Add</button> */}
            <button className="btn2 col-2 col-sm-1" onClick={() => {
              console.log(selectedRecords)
            }}><i className="fas fa-edit"></i> Edit</button>
            <button className="btn2 col-2 col-sm-1" type="submit"><i className="fa fa-save"></i> Save</button>
            <button className="btn2 col-2 col-sm-1" type="submit"> Delete</button>
            <button className="btn2 col-2 col-sm-1" type="submit"><i className="fa fa-close"></i> Cancel</button>
            <button className="btn2 col-2 col-sm-1" type="submit"> <i className="fa fa-sign-out" aria-hidden="true"></i> Close</button>
            <button className="btn2 col-2 col-sm-1" onClick={() => {
              console.log(selectedRecords)
            }}><i className="fa fa-search" aria-hidden="true"></i> Print</button>
          </div>
          <Footer />
        </>
      );
    }

export default CourierList