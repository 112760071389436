import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const InwardContainerHeader = () => {

    const [classhideHeader, setclasshideHeader] = useState('');
    const hideHeader = () => {
        setclasshideHeader("hideHeader");
    }
    return (
        <div className='row col-12' >
            <ul className="nav nav-pills col-12 " id="pills-tab" role="tablist">
                <Link to="/inwardloglist" className="navbar-item inwordbtn" >Inward Log Entry</Link>
                <Link to="/inworddetailslist" className="navbar-item inwordbtn" >Inward Detail Entry</Link>
                <Link to="/inwarddrslist" className="navbar-item inwordbtn" >Inward Delivery Sheet</Link>
                {/* <Link to="/" className="navbar-item inwordbtn" >Inward Outscan</Link> */}
                <Link to="/inwarddbstatus" className="navbar-item inwordbtn" >Inward DS Status</Link>
                <Link to="/" className="navbar-item inwordbtn" >Inward Tracking</Link>
                <Link to="/inwardemail" className="navbar-item inwordbtn" >Inward Email</Link>
                {/* <Link to="/inwardmail" className="navbar-item inwordbtn" >Inward Mail</Link> */}
            </ul>
            {/* <ul>
                    <a className={classhideHeader} onClick={hideHeader}> Skip Header</a>
                </ul> */}

        </div>
    )
}

export default InwardContainerHeader