import { Link } from 'react-router-dom';
function SettingHeader() {
  return (
    <>
      <div className='row col-12'>
        <ul className="nav nav-pills col-12" id="pills-tab" role="tablist">
          <Link className="navbar-item inwordbtn" to="/settinghome" >Settings </Link>
          <Link className="navbar-item inwordbtn" to="/userlist" > Users </Link>
          <Link className="navbar-item inwordbtn" to="/mailroom" > MailRoom </Link>
          <Link className="navbar-item inwordbtn" to="/branchlist" > Branch </Link>
        </ul>
      </div>
    </>

  );
} export default SettingHeader;