import '../headerfooter/headerfootercss.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import HeaderTop from './HeaderTop';
import FooterMobileNav from './footerMobileNav';
import { ReactComponent as MisSVG } from "../../assets/SVG/MIS.svg";
import { ReactComponent as MasterSVG } from "../../assets/SVG/Master.svg";
import { ReactComponent as ReportSVG } from "../../assets/SVG/Report.svg";
import { ReactComponent as AccountSVG } from "../../assets/SVG/Accounts.svg";
import { ReactComponent as DashboardSVG } from '../../assets/SVG/Dashboard.svg';
import { ReactComponent as Transection } from '../../assets/SVG/Transection.svg';
import { useState } from 'react';
import { Link } from 'react-router-dom';


function MailitNavbar() {
  const [show, setShow] = useState(true);
  const [skipHeader, setskipHeader] = useState("Skip Header");
    function hideHeader() {
        if (show === true) {
            setShow(false)
            setskipHeader("Open Header")
        } else {
            setShow(true)
            setskipHeader("Skip Header")
        }
    }

  return (
    <>    
      <div className='sticky onTop'>
        <HeaderTop hideHeader={hideHeader} skipHeader = {skipHeader}/>
        {show && ( 
        <div className='navbar1' style={{marginBottom:'5px'}}>
          <div className='col-12 col-sm-6 header-image1'><Link to='/dashboard'><img className='header-image margin-top' src='./assets/upperside-image.png' /></Link></div>
          <div className='col-6 col-sm-6  d-none d-lg-block'>
            <div className='inline row right-icons margin-top'>
              <Link className="nav-link" to="/dashboard">
                  <DashboardSVG />
                  <p className='nav-icon-name'>Dashbord</p><span className="sr-only">(current)</span>
              </Link>
              <Link className="nav-link " to="../transaction">
                 <Transection />
                <p className='nav-icon-name'>Transanction</p><span className="sr-only">(current)</span>
              </Link>
              <Link className="nav-link " to="../masterhome">
                <MasterSVG />
                <p className='nav-icon-name' >Master</p><span className="sr-only">(current)</span>
              </Link>
              <Link className="nav-link " to="/accounthome">
                <AccountSVG />
                <p className='nav-icon-name'>Account</p><span className="sr-only">(current)</span>
              </Link>
              <Link className="nav-link " to="/inwardreport">
                <ReportSVG />
                <p className='nav-icon-name'>Report</p><span className="sr-only">(current)</span>
              </Link>
              <Link className="nav-link " style={{ margin: "" }} to="/">
                <MisSVG />
                <p className='nav-icon-name'>Mis</p><span className="sr-only">(current)</span>
              </Link>
            </div>
          </div>

        </div>
        )}
        <FooterMobileNav />
      </div>
    </>

  );
} export default MailitNavbar;