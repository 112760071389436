import axios from "axios";


const API_URL = process.env.REACT_APP_BASE_URL_API;
const token = `Bearer ` + localStorage.getItem('token');
const createdBy =  localStorage.getItem('userId');
const updatedBy =  localStorage.getItem('userId');
const mailRoomId =  localStorage.getItem('mailRoomId');
const branch =(localStorage.userDetails) ? JSON.parse(localStorage.userDetails).branch : "";
const config = {
    headers: {
        'Authorization': token,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
    }
};


// ShipmentModeSubmit------------------------------------------------------------------------------------------------------
const PostshipmentMode = async (modeName: String,shortName: String) => {
    const bodyParamter = {
        modeName: modeName,
        shortName :shortName,
        mailRoomId,
        branch
    };
    return axios.post(API_URL + "shipmentmode/register", bodyParamter, config).then(
        (response) => {
            return response.data.message
        })
        .catch(error => console.log(error))

}
// ShipmentModeedit------------------------------------------------------------------------------------------------------
const PutshipmentMode = async (modeName: String,shortName: String, id:any) => {
    const bodyParamter = {
        modeName: modeName,
        shortName :shortName
    };
    return axios.put(API_URL + "shipmentmode/"+id, bodyParamter, config).then(
        (response) => {
            return response.data.message
        })
        .catch(error => console.log(error))

}
// ShipmentModedelete------------------------------------------------------------------------------------------------------
const DeleteshipmentMode = async (id:any) => {
   
    return axios.delete(API_URL + "shipmentmode/"+id,  config).then(
        (response) => {
            return response.data.message
        })
        .catch(error => console.log(error))

}
// ShipmentModeGet -------------------------------------------------------------------------------------------------------

const GetshipmentMode = async () => {
    const bodyParamter = {
        mailRoomId,
        branch
    };
    return axios.post(API_URL + "shipmentmode/",bodyParamter, config).then(
        (response) => {
            return response.data.message
        }
    )
        .catch(error => console.log(error))
        
 
}
const GetshipmentModeId = async (id:any) => {

    return axios.get(API_URL + "shipmentmode/"+id, config).then(
        (response) => {
            return response.data.message

        }
    )
        .catch(error => console.log(error))
        
 
}


// ServiceProviderSubmit---------------------------------------------------------------------------------------------------------------------------

const PostServiceProvider = async (serviceProviderName: String, contactPerson: String, phoneNumber: String,contactPersonEmail:any, address:String,  pincode:Number, city:Number, stateName:Number, country:Number) => {
    const bodyParamter = {
        serviceProviderName,
        contactPerson,
        phoneNumber,
        contactPersonEmail,
        address,
        countryId: country,
        stateId: stateName,
        cityId:city,
        zipCode :pincode,
        tCode: Date.now().toString(),
        createdBy,
        mailRoomId,
        branch
    };
    return axios.post(API_URL + "serviceprovider/register", bodyParamter, config).then(
            (response) => {
                return response.data.message;
            })
        .catch(error => console.log(error));

}

// ServiceProvideredit---------------------------------------------------------------------------------------------------------------------------

const PutServiceProvider = async (serviceProviderName: String, contactPerson: String, phoneNumber: String, contactPersonEmail:any, address:String, pincode:Number, city:Number, stateName:Number, country:Number, id:Number,) => {
       const bodyParamter = {
        serviceProviderName,
        contactPerson,
        phoneNumber,
        contactPersonEmail,
        address,
        countryId: country,
        stateId: stateName,
        cityId:city,
        zipCode :pincode,
        tCode: Date.now().toString(),
        serviceProviderStatus:1,
        updatedBy 
        };
        
    return axios
        .put(API_URL + "serviceprovider/"+id, bodyParamter, config).then(
            (response) => {
                return response.data.message;
            })
        .catch(error => console.log(error));

}
// ServiceProvidedelete---------------------------------------------------------------------------------------------------------------------------

const DeleteServiceProvider = async (id:any) => {    
    return axios
        .delete(API_URL + "serviceprovider/"+id, config).then(
            (response) => {
                return response.data.message;
            }).catch(error => console.log(error));
}

// serviceproviderGet--------------------------------------------------------------------------------------------------------------
const GetserviceProviderbybranch = async (branch:number) => {
    const bodyParamter ={
    branch :branch,
    mailRoomId
}
    return axios.post(API_URL + "serviceprovider/",bodyParamter, config).then(
        (response) => {
            if(response.data.statuscode === "200"){
                return response.data.message
            }

        }
    )
        .catch(error => console.log(error))

}

const GetserviceProvider = async () => {
    const bodyParamter = {
        mailRoomId,
        branch
    };
    return axios.post(API_URL + "serviceprovider/", bodyParamter, config).then(
        (response) => {
            if(response.data.statuscode === "200"){
                return response.data.message
            }

        }
    )
        .catch(error => console.log(error))

}
const GetserviceProviderId = async (id:any) => {
  

    return axios.get(API_URL + "serviceprovider/"+id, config).then(
        (response) => {
            return response.data.message
        }
    )
        .catch(error => console.log(error))

}
//----------------Emplyee Master -------------

const getEmployeeList = () => {
    const bodyParamter = {
        mailRoomId,
        branch
    };
    return axios.post(API_URL + "employee/" ,bodyParamter, config).then(
        (response) => {
            return response.data.message
        }
    ).catch(error => console.log(error))

}
const GetemployeeId = (id:any) => {
    return axios.get(API_URL + "employee/"+id , config).then(
        (response) => {
            return response.data.message
        }
    ).catch(error => console.log(error))

}
const PostEmployeeList = async (
    employeeId : String, employeeName: String, emailId: String,notifyOnMail:string, phoneNumber: String, extension: String, department :Number, division : String, floor: String, costCenter :Number, address: String, selectedCountryId:Number, selectedStateId:Number, selectedCityId :Number, pincodeId :Number, routeId: Number
    // employeeId:any,employeeName:any,emailId:any,phoneNumber:any,extension:any,department:any,division:any,cityName:any,company:any,floor:any,costCenter:any,routeId:any
    ) => {
    const bodyParamter = {
        employeeId,
        employeeName,
        emailId,notifyOnMail,
        phoneNumber,
        extension,
        department,
        division,
        floor,
        address,
        costCenter,
        routeId,
        countryId : selectedCountryId,
        stateId :selectedStateId,
        cityId : selectedCityId,
        zipcode : pincodeId,
        tCode : Date.now().toString(),
        mailRoomId,
        branch,
        createdBy
    };
    return axios
        .post(API_URL + "employee/register", bodyParamter, config).then(
            (response) => {
                return response.data.message;
            })
        .catch(error => console.log(error));

}
const PutEmployee = async ( employeeId : String, employeeName: String, emailId: String, notifyOnMail:string, phoneNumber: String, extension: String, department :Number, division : String, floor: String, costCenter :Number, address: String, selectedCountryId:Number, selectedStateId:Number, selectedCityId :Number, pincodeId :Number, routeId: Number, Id:Number) => {
    const bodyParamter = {
        employeeId,
        employeeName,
        emailId,notifyOnMail,
        phoneNumber,
        extension,
        department,
        division,
        floor,
        address,
        costCenter,
        routeId,
        countryId : selectedCountryId,
        stateId :selectedStateId,
        cityId : selectedCityId,
        zipcode : pincodeId,
        tCode : Date.now().toString(),
        updatedBy
    };
    return axios
        .put(API_URL + "employee/"+Id, bodyParamter, config).then(
            (response) => {
                return response.data.message;
            })
        .catch(error => console.log(error));

}
const deleteEmployee = async (id:any)  => {
    return axios.delete(API_URL + "employee/"+id, config).then(
        (response) => {
            return response.data.message;
        }).catch(error => console.log(error));  
}

const getBranchEmployeeByBranchId = (branch : Number) => {
    const bodyParamter = {
        mailRoomId,
        branch : branch
    };
    return axios.post(API_URL + "employee/" ,bodyParamter, config).then(
        (response) => {
            return response.data.message
        }
    ).catch(error => console.log(error)) 
}

//--------------------------End Employee ---------------

// deliveryBoySubmit-----------------------------------------------------------------------------------------------------------------

const PostDeliveryBoyResister = async (deliveryBoyId: String,
    deliveryBoyName: String,
    emailId: String,
    phoneNumber: String) => {
    const bodyParamter = {

        deliveryBoyId: deliveryBoyId,
        deliveryBoyName: deliveryBoyName,
        emailId: emailId,
        phoneNumber: phoneNumber,
        tCode:Date.now().toString(), 
        mailRoomId,
        branch, 
        createdBy

    };

    return axios.post(API_URL + "deliveryboy/register", bodyParamter, config).then(
        (response) => {
            return response.data.message;
        }
    )
        .catch(error => console.log(error));

}
// deliveryBoyEdit-----------------------------------------------------------------------------------------------------------------

const PutDeliveryBoyResister = async (deliveryBoyId:String,deliveryBoyName:String,emailId:String,phoneNumber:String, id:any) => {
    const bodyParamter = {
        deliveryBoyId,
        deliveryBoyName,
        emailId,
        phoneNumber,
        tCode:Date.now().toString(), 
        updatedBy 
    };

    return axios.put(API_URL + "deliveryboy/"+id, bodyParamter, config).then(
        (response) => {           
            return response.data.message;
        }
    )
        .catch(error => console.log(error));

}
// deliveryBoyDelete-----------------------------------------------------------------------------------------------------------------

const DeleteDeliveryBoyResister = async (id:any) => { 
    return axios.delete(API_URL + "deliveryboy/"+id,  config).then(
        (response) => {           
            return response.data.message;
        }
    ).catch(error => console.log(error));

}
// deliveryBoyGet------------------------------------------------------------------------------------------------------------------

const GetdeliveryBoy = async () => {
    const bodyParamter = {
        mailRoomId,      
        branch
        };
    return axios.post(API_URL + "deliveryboy/",bodyParamter, config).then(
        (response) => {
            return response.data.message
        }
    )
        .catch(error => console.log(error))

}
const GetdeliveryBoyId = async (id:any) => {

    return axios.get(API_URL + "deliveryboy/"+id, config).then(
        (response) => {
            return response.data.message

        }
    )
        .catch(error => console.log(error))

}

// courierSubmit-----------------------------------------------------------------------------------------------------------------------


const Postcourier = async () => {
    axios.get(API_URL + "").then(
        (response) => {
            if (response.data.statuscode === "200") {
                return {}
            }
        }
    )
        .catch(error => console.log(error))
}


// courierGet-----------------------------------------------------------------------------------------------------------------------

const getcourier = async (modeName: String) => {
    const bodyParamter = {
        mailRoomId,      
        branch
        };
    return axios.post(API_URL + "",bodyParamter, config).then(
        (response) => {
            return response.data

        }
    )
        .catch(error => console.log(error))

}
// getcountry-----------------------------------------------------------------------------------------------------------------------

const getcountry = async () => {
    return axios.get(API_URL + "country/", config).then(
        (response) => {             
            return response.data.message
        }
    )
        .catch(error => console.log(error))
}
// getState-----------------------------------------------------------------------------------------------------------------------

const getState = async (selectedCountryId:any) => {
    return axios.get(API_URL + "state/getbycountry/"+selectedCountryId, config).then(
        (response) => {
            return response.data.message
        }
    )
        .catch(error =>{
            return '';
            // console.log(error)
        })

}
// getCity-----------------------------------------------------------------------------------------------------------------------

const getCity = async (selectedStateId:any) => {
    return axios.get(API_URL + "city/getbystate/"+selectedStateId, config).then(
        (response) => {
            return response.data.message
        }
    ).catch(error => { return '';})

}
// getCity-----------------------------------------------------------------------------------------------------------------------

const getPincode = async (selectedCityId:any) => {

    return axios.get(API_URL + "zipcode/getPincodeByCity/"+selectedCityId, config).then(
        (response) => {
            return response.data.message
        }
    ).catch(error => {return ""})

}

const getAllPincode = async () => {
    return axios.get(API_URL + "zipcode/", config).then(
        (response) => {
            return response.data.message
        }
    ).catch(error => {return ""})  
}
const getAllPincodeByCountryWise = async (courierType : Number) => {
    const bodyParamter = {
        mailRoomId,      
        branch,
        courierType
        };
    return axios.post(API_URL + "zipcode/bycouriertype/", bodyParamter, config).then(
        (response) => {
            return response.data.message
        }
    ).catch(error => {return ""})  
}
const getAllPincodeByCountryWiseAutoComplte = async (courierType : Number, inputs :any) => {
    const bodyParamter = {
        mailRoomId,      
        branch,
        courierType,
        input :inputs
        };
    return axios.post(API_URL + "zipcode/bycouriertypeautocomplete/", bodyParamter, config).then(
        (response) => {
            return response.data.message
        }
    ).catch(error => {return ""})  
}

const getPincodeCityInfo = async (zipCode: any) => {
    const bodyParamter = {
        mailRoomId,      
        branch,
        zipCode
        };
        console.log("bodyParamter", bodyParamter)
    return axios.post(API_URL + "zipcode/getcityinfo", bodyParamter, config).then(
        (response) => {
            return response.data.message
        }
    ).catch(error => {return ""})   
}
// getDepartment-----------------------------------------------------------------------------------------------------------------------

const getDepartment = async () => {
    const bodyParamter = {
        mailRoomId,      
        branch
        };
    return axios.post(API_URL + "department/", bodyParamter, config).then(
        (response) => {
            return response.data.message

        }
    )
        .catch(error => console.log(error))
}
// Cost Center-----------------------------------------------------------------------------------------------------------------------

const getCostCenter = async () => {
    const bodyParamter = {
        mailRoomId,      
        branch
        };
    return axios.post(API_URL + "costcenter/", bodyParamter, config).then(
        (response) => {           
            if(response.data.statuscode === "200"){
                return response.data.message
            }
        }
    ).catch(error => console.log(error))

}
// getCompany-----------------------------------------------------------------------------------------------------------------------

const getCompany = async () => {

    return axios.get(API_URL + "company/", config).then(
        (response) => {
            return response.data.message

        }
    )
        .catch(error => console.log(error))

}
//------------------- getRouteId-----------------------------------------------------------------------------------------------------------------------

const getRouteId = async () => {
    const bodyParamter = {
        mailRoomId,      
        branch
        };
    return axios.post(API_URL + "deliveryroute/", bodyParamter, config).then(
        (response) => {           
            if(response.data.statuscode === "200"){
                return response.data.message
            }
        }
    ).catch(error => {return ""})

}
// ------------------------------getBranchId-----------------------------------------------------------------------------------------------------------------------

const getBranch = async () => {
    const bodyParamter = {
        mailRoomId
        };
    return axios.post(API_URL + "branch/",bodyParamter, config).then(
        (response) => {
            return response.data.message

        }
    )
        .catch(error => console.log(error))

}
//------------ Regulators -------------------
const getRegulators = async () => {
    const bodyParamter = {
        mailRoomId,
        branch
    };
    return await axios.post(API_URL + "regulator/",bodyParamter, config).then(
        (response) => {
            return response.data.message
        }
    ).catch(error => console.log(error))

}

const putRegulators = async (regulatorName :string, deliveryTime:string, id:Number) => {
    const bodyParams = {
        regulatorName,
        deliveryTime,
        regulatorStatus : 1
    }
   return await axios.put(API_URL + "regulator/"+ id, bodyParams, config).then(
        (response) => {
            if (response.data.statuscode === "200") {
                return response.data
            }
        }
    )
        .catch(error => console.log(error))
}
const PostRegulators = async (regulatorName :string, deliveryTime:string) => {
    const bodyParams = {
        regulatorName,
        deliveryTime,
        mailRoomId,
        branch,
        regulatorStatus : 1
    }
   return await axios.post(API_URL + "regulator/register", bodyParams, config).then(
        (response) => {
            if (response.data.statuscode === "200") {
                return response.data.message
            }
        }
    )
        .catch(error => console.log(error))
}

const deleteRegulators = async (id:any) => {

    return axios.delete(API_URL + "regulator/"+id,  config).then(
        (response) => {
            return response.data
        })
        .catch(error => console.log(error))

}
const getRegulatoryId = async (id:Number) => {
    return await axios.get(API_URL + "regulator/"+id, config).then(
        (response) => {
            return response.data.message
        }
    ).catch(error => console.log(error))

}





//----------------------End Regulators ------------
const getRoles = async () => {
    return await axios.get(API_URL + "role/", config).then(
        (response) => {
            return response.data.message
        }
    ).catch(error => console.log(error))

}
//------------------------------------------------------------------------------------------------------------------------

// const MailSubmit = async (title: any,
//     signature: any,
//     receiver: any,
//     subject: any,
//     content: any,
//     to: any,
//     fromDate: any,
//     toDate: any,



// ) => {
//     const bodyParamter = {

//         // "fromDate": "2023-06-23",
//         // "toDate": "2023-06-29",
//         // "to": "pratikvitkare007@gmail.com",
//         title: title,
//         // "branch": 2,
//         // "receiver": "Sanyog Softwares",
//         // "subject": "Daily MIS Report Test Mail",
//         // "content": "Attaching herewith excel file of auto generated mis report for branch no. 2.",
//         // "signature": "Regards,\nSanyog Softwares,\nPune - 412015.",



//         fromDate: fromDate,
//         toDate: toDate,
//         to: to,

//         // branch: branch,
//         receiver: receiver,
//         subject: subject,
//         content: content,
//         signature: signature


//     };
//     return axios
//         .post(API_URL + "misreport/getreportbymail", bodyParamter, config).then(
//             (response) => {

//                 return response.data;
//             })
//         .catch(error => console.log(error));

// }
// -------------------------------------------------------------------------------------------------------------------


const MasterDataService = {
    PostshipmentMode,
    Postcourier,
    PostDeliveryBoyResister,
    PostServiceProvider,
    PutServiceProvider,
    DeleteServiceProvider,
    PutDeliveryBoyResister,
    GetshipmentMode,
    GetserviceProvider,
    GetdeliveryBoy,
    getcourier,
    GetserviceProviderbybranch,
    GetserviceProviderId,
    GetshipmentModeId,
    GetdeliveryBoyId,
    getCostCenter,
    getcountry,
    getState,
    getCity,
    getAllPincode,
    getAllPincodeByCountryWise,
    getPincode,
    getPincodeCityInfo,
    getAllPincodeByCountryWiseAutoComplte,
    getDepartment,
    getCompany,
    getRouteId,
    PutshipmentMode,
    DeleteshipmentMode,
    DeleteDeliveryBoyResister,
    getEmployeeList,
    deleteEmployee,
    PostEmployeeList,
    GetemployeeId,
    PutEmployee,
    getBranchEmployeeByBranchId,
    getBranch,
    PostRegulators,
    getRegulators,
    deleteRegulators,
    getRegulatoryId,
    putRegulators,
    getRoles,

}

export default MasterDataService