import { useEffect, useState } from 'react'
import MasterHeader from './masterHeader';
import MasterDataService from '../../services/masterData.service';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import Select from 'react-select';

const customStyles = {
  option: (provided: any, { isFocused, isSelected }: any) => ({
    ...provided,
    padding: 5,
    zIndex: 1,
    background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected ? 'hsl(0deg 0% 20% / 65%)' : undefined,
  }),
  control: (prev: any) => ({
    ...prev,
    // none of react-select's styles are passed to <Control />
    padding: 0,
    width: "100%"

  }),
  menu: (base: any, { isFocused, isSelected }: any) => ({
    ...base,
    zIndex: 100,
    background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected ? 'hsl(0deg 0% 20% / 65%)' : undefined,
  }),
}
const Employee = () => {

  const [employeeId, setemployeeId] = useState('');
  const [employeeName, setemployeeName] = useState('');
  const [emailId, setemailId] = useState('');
  const [phoneNumber, setphoneNumber] = useState('');
  const [extension, setextension] = useState('');
  const [department, setdepartment] = useState(0);
  const [departmentLabel, setdepartmentLabel] = useState('');
  const [departmentvalue, setdepartmentvalue] = useState([]);
  const [division, setdivision] = useState('');
  const [floor, setfloor] = useState('');
  const [costCenter, setcostCenter] = useState(0);
  const [allCostCenter, setallCostCenter] = useState([]);
  const [costCenterLabel, setcostCenterLabel] = useState('');
  const [routeId, setrouteId] = useState(0);
  const [address, setAddress] = useState('');
  const [allCountry, setAllCountry] = useState([]);
  const [country, setCountry] = useState('');
  const [selectedCountryId, setselectedCountryId] = useState(0);
  const [allBranch, setallBranch] = useState([]);
  const [branch, setBranch] = useState(0);
  const [branchLabel, setBranchLabel] = useState('');
  const [statevalue, setstatevalue] = useState([]);
  const [stateName, setStateName] = useState('')
  const [selectedStateId, setselectedStateId] = useState(0)
  const [notifyOnMail, setnotifyOnMail] = useState('')


  const [cityvalue, setcityvalue] = useState([]);
  const [city, setCity] = useState('');
  const [selectedCityId, setselectedCityId] = useState(0)


  const [pinvalue, setpinvalue] = useState([]);
  const [pincodeId, setPincodeId] = useState(0);
  const [pincode, setPincode] = useState('');
  const [firstCall, setFirstCall] = useState(false)



  const navigate = useNavigate();

  const HandleSubmit = () => {
    MasterDataService.PostEmployeeList(employeeId, employeeName, emailId, notifyOnMail, phoneNumber, extension, department, division, floor, costCenter, address, selectedCountryId, selectedStateId, selectedCityId, pincodeId, routeId).then(
        (data) => {
          if (data) {
            navigate("../employeelist");
          }
        }

      ).catch((error) => {
        console.log(error);
        console.log("server Responding");
      })
  }
  // get deliverry by id
  const { state } = useLocation();

  const GetemployeeId = async () => {
    if(state){
    try {
      const data = await MasterDataService.GetemployeeId(state);
      if (data) {
        // console.log(data)
        setemployeeId(data.employeeId);
        setemployeeName(data.employeeName);
        setemailId(data.emailId);
        setphoneNumber(data.phoneNumber);
        setselectedCountryId(data.countryId);
        setselectedStateId(data.stateId);
        setselectedCityId(data.cityId);
        setPincodeId(data.zipcode);
        setextension(data.extension);
        setdivision(data.division);
        setBranch(data.branch)
        setfloor(data.floor)
        setcostCenter(data.costCenter)
        setAddress(data.address)
        setdepartment(data.department)
        setrouteId(data.routeId)
        setnotifyOnMail(data.notifyOnMail)
        setFirstCall(true)
        // ... and so on for other properties
      } else {
        setFirstCall(true)
        // Handle the case when data.message is undefined or null
      }
    } catch (error) {
      // Handle errors from the API call
      console.error(error);
    }
  }
  };
  const HandleEdit = () => {
    if(state) {
         MasterDataService.PutEmployee(employeeId, employeeName, emailId, notifyOnMail, phoneNumber, extension, department, division, floor, costCenter, address, selectedCountryId, selectedStateId, selectedCityId, pincodeId, routeId, state).then(
        (data) => {
          if (data ) {
            navigate("../employeelist");
          }
        }

      ).catch((error) => {
        console.log(error);
        console.log("server Responding");
      })
    }
  }
  const getCostCenter = async () => {
    await MasterDataService.getCostCenter().then(
      async (data) => {
        if(data){
        const options = data.map((d: any) => ({
          "value": d.id,
          "label": d.costCenter
        }))
        await setallCostCenter(options)
        if (state === null) {
          await setcostCenter(data[0]['id'])
          setcostCenterLabel(data[0]['costCenter'])
        } else {
          let costCenters = data.find((item: any) => {
            return item.id === costCenter
          });
          if (costCenters !== undefined) {
            setcostCenterLabel(costCenters.costCenter)
          }
        }
      }
      }
    )
  }
  // get country values
  const getcountry = async () => {
    await MasterDataService.getcountry().then(
      async (data) => {
        if(data){
        const options = data.map((d: any) => ({
          "value": d.id,
          "label": d.countryName
        }))
        await setAllCountry(options)
        if(state === null){
        if (data[0]) {
        await setselectedCountryId(data[0]['id'])
        await setCountry(data[0]['countryName'])
        }
      } else {
        let CountryLists = data.find((item: any) => {
          return item.id === selectedCountryId
        });
        if (CountryLists !== undefined) {
          setCountry(CountryLists.countryName)
        }
      }
    }
      }
    )
  }
  useEffect(() => {
    GetemployeeId();
  }, [])
  useEffect(() => {
    getcountry();
    getDepartment();
    getBranch();
    getRouteId();
    getCostCenter();
  }, [firstCall])

  useEffect(() => {
    getState(selectedCountryId);
  }, [selectedCountryId])
  useEffect(() => {
    getCity(selectedStateId);
  }, [selectedStateId])
  useEffect(() => {
    getPincode(selectedCityId);
  }, [selectedCityId])

  const handleCountryChange = async (e: any) => {
    await setselectedCountryId(e.value)
    await setCountry(e.label)
    setselectedStateId(0)
    setStateName('')
    setselectedCityId(0)
    setCity('')
    setPincodeId(0)
    setPincode('')

  };

  // get state values
  const getState = async (value: Number) => {
    await MasterDataService.getState(value).then(
      async (data) => {
        if(data){
          const statesList = data.map((d: any) => ({
            "value": d.id,
            "label": d.stateName
          }))
          setstatevalue(statesList)
          if (state === null) {
            if (data[0]) {
           await setselectedStateId(data[0]['id']);
           await setStateName(data[0]['stateName']);
            }
        }   else {
          let StateNames = data.find((item: any) => {
            return item.id === selectedStateId
          });
          if (StateNames !== undefined) {
            setStateName(StateNames.stateName)
          }
        }     
        }
      }
    )
  }

  const handleStateChange = (e: any) => {
    setselectedStateId(e.value)
    setStateName(e.label) 
    setselectedCityId(0)
    setCity('')
    setPincodeId(0)
    setPincode('')
    // getCity(e.value);

  };

  // get city values
  const getCity = async (value: Number) => {
    await MasterDataService.getCity(value).then(
      (data) => {
        const cityList = data.map((d: any) => ({
          "value": d.id,
          "label": d.cityName
        }))
        setcityvalue(cityList)
        if (state === null) {
          if (data[0]) {
          setselectedCityId(data[0]['id'])
          setCity(data[0]['cityName'])
          }
        }else {
          let CityLists = data.find((item: any) => {
            return item.id === selectedCityId
          });
          if (CityLists !== undefined) {
            setCity(CityLists.cityName)
          }
        }
      }
    )
  }

  const handleCityChange = (e: any) => {
    setselectedCityId(e.value)
    setCity(e.label)
    setPincodeId(0)
    setPincode('')


  };
  const getPincode = async (value: Number) => {
    await MasterDataService.getPincode(value).then(
      (data) => {
        const zipCodeList = data.map((d: any) => ({
          "value": d.id,
          "label": d.zipCode
        }))
        setpinvalue(zipCodeList)
        if (state === null) {
          if (data[0]) {
          setPincodeId(data[0]['id'])
          setPincode(data[0]['zipCode']);
          }
        }else {
          let pinCodeLists = data.find((item: any) => {
            return item.id === pincodeId
          });
          if (pinCodeLists !== undefined) {
            setPincode(pinCodeLists.zipCode)
          }
        }
      }
    )
  }

  const handlePinChange = (e: any) => {
    setPincodeId(e.value)
    setPincode(e.label);
  };
  //  get department options
  const getDepartment = async () => {
    await MasterDataService.getDepartment().then(
      async (data) => {
        if(data){
        const options = data.map((d: any) => ({
          "value": d.id,
          "label": d.name
        }))
        await setdepartmentvalue(options)
        if(state === null){
        if (data[0]) {
        await setdepartment(data[0]['id'])          
        await setdepartmentLabel(data[0]['name'])   
        }  
      } else {
        let departmentLists = data.find((item: any) => {
          return item.id === department
        });
        if (departmentLists !== undefined) {
          setdepartmentLabel(departmentLists.name)
        }
      }     
      }

      }
    )
  }

  const handleDepartmentChange = async (e: any) => {
    await setdepartment(e.value)
    await setdepartmentLabel(e.label)
  };
  //  get branch options
  const getBranch = async () => {
    await MasterDataService.getBranch().then(
      async (data) => {
        if(data){
        const options = data.map((d: any) => ({
          "value": d.id,
          "label": d.branchName
        }))
        await setallBranch(options)
        if(state === null){
          if (data[0]) {
        await setBranch(data[0]['id'])
        await setBranchLabel(data[0]['branchName'])
          }} else {
            let branchLists = data.find((item: any) => {
              return item.id === branch
            });
            if (branchLists !== undefined) {
              setBranchLabel(branchLists.branchName)
            }
          }
      }
      }
    )
  }

  const handleBranchChange = async (e: any) => {
    await setBranch(e.value)
    await setBranchLabel(e.label)
  };
  //  get RouteID options
  const [RouteIDvalue, setRouteIDvalue] = useState([]);
  const[routeName,setrouteName] = useState([])
 
  const getRouteId = async () => {
    await MasterDataService.getRouteId().then(
      async (data) => {
        const options = data.map((d: any) => ({
          "value": d.id,
          "label": d.routeName
        }))
        await setRouteIDvalue(options)
        if(state === null){
          if (data[0]) {
          await setrouteId(data[0]['id'])
        await setrouteName(data[0]['routeName'])
          }} else {
            let routesList = data.find((item: any) => {
              return item.id === routeId
            });
            if (routesList !== undefined) {
              setrouteName(routesList.routeName)
            }
          }

      }
    )
  }

  const handleRouteIdChange = async (e: any) => {
    await setrouteId(e.value)
    await setrouteName(e.label)
  };

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })

  const handleDelete = () => {
    if (state) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          MasterDataService.deleteEmployee(state)
            .catch((error) => {
              console.log(error);
              console.log("server responding");

            })
          Swal.fire({
            title: "Deleted",
            icon: 'success',
            text: "Employee has been deleted",
          }).then(function () {
            navigate("../employeelist")
          })
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            {
              title: "Cancelled",
              icon: 'error',
              text: "Employee is safe :)",
            }).then(function () {
              navigate("../employeelist")
            }
            )
        }
      })
    }
  }
  const HandleCancel = () => {
    setemailId('')
  }
  const handleCostCenter = (e: any) => {
    setcostCenter(e.value)
    setcostCenterLabel(e.label)
  }
  return (
    <>
      <MasterHeader />
      <div style={{ margin: "0px" }}>
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}></div>
        <div className='container col-10' >
          <div className="themeColor" style={{ width: '100%' }} ><p style={{ marginLeft: "10px" }}> Employee Register </p></div>
          <div className='row'>
            <div className='col-6'>
              <div className='col-12'>
                <label className='col-4'><b>Employee Id</b></label>
                <input type='text' className='col-8' name='employeeId' value={employeeId} onChange={e => setemployeeId(e.target.value)} />
              </div>
              <div className='col-12'>
                <label className='col-4'><b> Name </b></label>
                <input type='text' className='col-8' name='employeeName' value={employeeName} onChange={e => setemployeeName(e.target.value)} />
              </div>

              <div className=' col-12'>
                <label className='col-4'><b> Extension </b></label>
                <input type='text' className='col-8' name='extension' value={extension} onChange={e => setextension(e.target.value)} />
              </div>              
              <div className='col-12 inline'>
                <label className='col-4'><b> Branch </b></label>
                <Select
                  name="branch"
                  onChange={handleBranchChange}
                  options={allBranch}
                  value={{ value: branch, label: branchLabel }}
                  className='col-8'
                  styles={customStyles}
                />
              </div>
              <div className='col-12'>
                <label className='col-4'><b> Floor </b></label>
                <input type='text' className='col-8' name='floor' value={floor} onChange={e => setfloor(e.target.value)} />
              </div>
              <div className='col-12 inline'>
                <label className='col-4'><b> CostCenter </b></label>
                <Select
                  name="costCenter"
                  onChange={handleCostCenter}
                  options={allCostCenter}
                  value={{ value: costCenter, label: costCenterLabel }}
                  className='col-8'
                  styles={customStyles}
                />
              </div>
              <div className=' col-12 inline'>
                <label className='col-4'><b> Department </b></label>
                <Select
                  name="department"
                  onChange={handleDepartmentChange}
                  options={departmentvalue}
                  value={{ value: department, label: departmentLabel }}
                  className='col-8'
                  styles={customStyles}
                />
              </div>
              <div className='col-12'>
                <label className='col-4'><b> Division </b></label>
                <input type='text' className='col-8' name='division' value={division} onChange={e => setdivision(e.target.value)} />
              </div>
              <div className='col-12 inline'>
                <label className='col-4'><b> RouteId </b></label>
                <Select
                  name="routeId"
                  onChange={handleRouteIdChange}
                  options={RouteIDvalue}
                  value={{ value: routeId, label: routeName }}
                  className='col-8'
                  styles={customStyles}
                />
              </div>
            </div>
            <div className='col-6'>

              <div className=' col-12'>
                <label className='col-4'><b> EmailId </b></label>
                <input type='Email' className='col-8' name='emailId' value={emailId} onChange={e => setemailId(e.target.value)} />
              </div>
              <div className=' col-12'>
                <label className='col-4'><b> Notify Email Id </b></label>
                <input type='Email' className='col-8' name='notifyOnMail' value={notifyOnMail} onChange={e => setnotifyOnMail(e.target.value)} />
              </div>
              <div className=' col-12'>
                <label className='col-4'><b> PhoneNo </b></label>
                <input type='text' className='col-8' name='phoneNumber' value={phoneNumber} onChange={e => setphoneNumber(e.target.value)} />
              </div>
              <div className=' col-12'>
                <label className='col-4'><b> Address </b></label>
                <input type='text' className='col-8' name='phoneNumber' value={address} onChange={e => setAddress(e.target.value)} />
              </div>
              <div className='col-12 inline'>
                <label className='col-4'><b> Country</b></label>
                <Select
                  name="country"
                  onChange={handleCountryChange}
                  options={allCountry}
                  className='col-8  select'
                  styles={customStyles}
                  value={{ value: selectedCountryId, label: country }}
                />
              </div>
              <div className='col-12 inline'>
                <label className='col-4'><b> State</b></label>
                <Select
                  name="stateName"
                  onChange={handleStateChange}
                  options={statevalue}
                  className='col-8 '
                  value={{ value: selectedStateId, label: stateName }}
                  styles={customStyles}
                />
              </div>
              <div className='col-12 inline'>
                <label className='col-4'><b> City</b></label>
                <Select
                  name="city"
                  onChange={handleCityChange}
                  options={cityvalue}
                  className='col-8 '
                  value={{ value: selectedCityId, label: city }}
                  styles={customStyles}
                />
              </div>
              <div className='col-12 inline'>
                <label className='col-4'><b> Pincode</b></label>
                <Select
                  name="pincode"
                  onChange={handlePinChange}
                  options={pinvalue}
                  className='col-8 '
                  value={{ value: pincodeId, label: pincode }}
                  styles={customStyles}
                />
              </div>

              
            </div>
          </div>
          {/* <div > <button onClick={HandleSubmit} className='btn2 col-12'> Submit</button></div> */}


        </div>
        <div className='btn d-flex justify-content-center'>
          {(state === null) ? <Link className="btn2 col-2 col-sm-1" onClick={HandleSubmit} to="/employeelist"><i className="fa fa-plus" aria-hidden="true"></i> submit</Link> : <>
            <Link className="btn2 col-2 col-sm-1" onClick={HandleEdit} to=""><i className="fa fa-edit"></i> Update </Link>
            <button className="btn2 col-2 col-sm-1" onClick={handleDelete}><i className="fa fa-trash" aria-hidden="true"></i> Delete </button> </>}
          <button className="btn2 col-2 col-sm-1" onClick={HandleCancel}><i className="fa fa-close"></i> Cancel</button>
          <Link className="btn2 col-2 col-sm-1" to="/employeelist"> <i className="fa fa-sign-out" aria-hidden="true"></i> Close</Link>
        </div>
      </div>
    </>
  )
}

export default Employee