// Logout.js
import React, { useEffect } from 'react';

const Logout = () => {
    
  const handleLogout = () => {
    localStorage.removeItem("userDetails" );  
    localStorage.removeItem('token' );
    localStorage.removeItem("userId" );  
    localStorage.clear();
    window.location.href = process.env.REACT_APP_PUBLIC_URL +""
  };
  useEffect(() => {
    handleLogout()
  }, [])
  return <button onClick={handleLogout}>Logout</button>;
};

export default Logout;
