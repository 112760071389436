
import MasterHeader from './masterHeader'



function Courier() {
  
  return (
    <>
  <MasterHeader/>
      <div style={{ display: "flex", justifyContent: "center" }}>
      <div className="redline3 redline col-9"><p className='m-0' style={{ marginLeft: "10px", alignItems: "center" }}><b>Courier</b><a /></p></div>
      </div>
      <div className=' card container' >


       

        {/* <div className=' col-sm-12 inputs'>
                     <label className='col-6 col-sm-2'> Time</label>
                     <input type='time'className='col-8 col-sm-4' name='Time'/>
                    </div> */}

        {/* <div className=' col-sm-12 inputs'>
          <label className='col-6 col-sm-2'> Mode</label>
          <select className='col-8 col-sm-4'>
          
           <option value='courier'>courier</option>
           <option value='delivery'>delivery</option>
            

          </select>
        </div> */}

        <div className=' col-sm-12 inputs'>
          <label className='col-6 col-sm-2'><b> Courier</b></label>
          <input type='text' className='col-8 col-sm-4'  />
        </div>




      
      </div>

      <div className='btn'>
          <button className="btn2 col-2 col-sm-1" type="submit"><i className="fa fa-plus" aria-hidden="true"></i> Add</button>
          <button className="btn2 col-2 col-sm-1" type="submit"><i className="fa fa-edit"></i> Edit</button>
          <button className="btn2 col-2 col-sm-1" type="submit"><i className="fa fa-save"></i> Save</button>
          <button className="btn2 col-2 col-sm-1" type="submit"> Delete</button>
          <button className="btn2 col-2 col-sm-1" type="submit"><i className="fa fa-close"></i> Cancel</button>
          <button className="btn2 col-2 col-sm-1" type="submit"> <i className="fa fa-sign-out" aria-hidden="true"></i> Close</button>
          <button className="btn2 col-2 col-sm-1" type="submit"><i className="fa fa-search" aria-hidden="true"></i> Search</button>
        </div>
    </>
  )
}

export default Courier