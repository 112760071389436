import React from 'react'
import { Link } from 'react-router-dom'

const Outcontainerheader = () => {
  return (
    <div>

<div className='row col-12' >

<ul className="nav nav-pills col-12 " id="pills-tab" role="tablist">

  <Link className="navbar-item inwordbtn" to="/outwardloglist" >Outward Log Entry
  </Link>

  <Link
    className="navbar-item inwordbtn" to="/outwarddetailslist" >Outward Detail Entry
  </Link>
  <Link
    className="navbar-item inwordbtn" to="/" >Outward Manifest
  </Link>
  {/* <Link
    className="navbar-item inwordbtn" to="/" >Outward Dispatch
  </Link> */}
  
  <Link
    className="navbar-item inwordbtn" to="/" >Outward Status
  </Link>
  {/* <Link
    className="navbar-item inwordbtn" to="/outwarddrs" >Outward DRS PDF
  </Link> */}
  <Link
    className="navbar-item inwordbtn" to="/" >Outward Email
  </Link>
</ul>

</div>
    </div>
  )
}

export default Outcontainerheader