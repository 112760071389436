import { useEffect, useState } from 'react'
import MasterHeader from './masterHeader';
import MasterDataService from '../../services/masterData.service';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';


const Delivery = () => {
  const [deliveryBoyId, setdeliveryBoyId] = useState('');
  const [deliveryBoyName, setdeliveryBoyName] = useState('');
  const [emailId, setemailId] = useState('');
  const [phoneNumber, setphoneNumber] = useState('');
  
  const navigate = useNavigate();

  const HandleSubmit = () => {
    MasterDataService.PostDeliveryBoyResister(deliveryBoyId, deliveryBoyName, emailId, phoneNumber).then(
        (data) => {
          if (data) {
            setdeliveryBoyId(data.deliveryBoyId);
            navigate("../deliverylist");
          }
        }

      ).catch((error) => {
        console.log(error);
        console.log("server Responding");
      })
  }
  const HandleEdit = () => {
    MasterDataService.PutDeliveryBoyResister(deliveryBoyId, deliveryBoyName, emailId, phoneNumber, state).then(
      (data) => {
        if (data) {
          navigate("../deliverylist");
        }
      }

    ).catch((error) => {
      console.log(error);
      console.log("server Responding");
    })
  }
  // get deliverry by id
  const { state } = useLocation();

  const GetdeliveryBoy = async () => {
    if(state){
    try {
      const data = await MasterDataService.GetdeliveryBoyId(state);
      if (data) {
        await setdeliveryBoyId(data.deliveryBoyId);
        await setdeliveryBoyName(data.deliveryBoyName);
        await setemailId(data.emailId);
        await setphoneNumber(data.phoneNumber);
        // ... and so on for other properties
      } else {
        // Handle the case when data is undefined or null
      }
    } catch (error) {
      // Handle errors from the API call
      console.error(error);
    }
  }
  };
  useEffect(() => {
    if(state){
      GetdeliveryBoy();
    }
  }, [])

  

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })
  const handleDelete = () => {
    if (state) {
      console.log("selectedRecords " + state);
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          MasterDataService.deleteEmployee(state)
            .catch((error) => {
              console.log(error);
              console.log("server responding");

            })
          Swal.fire({
            title: "Deleted",
            icon: 'success',
            text: "Delivery Person been deleted",
          }).then(function () {
            navigate("../deliverylist")
          })
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            {
              title: "Cancelled",
              icon: 'error',
              text: "Delivery Person is safe :)",
            }).then(function () {
             navigate("../deliverylist")
            }
            )
        }
      })
    }
  }
  const HandleCancel = () => {
    setemailId('')
  }
  return (
    <>
      <MasterHeader />
      <div style={{ margin: "0px" }}>
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}></div>
        <div className='container col-10' >
          <div className="themeColor" style={{ width: '100%' }} ><p style={{ marginLeft: "10px" }}> Delivery Boy </p></div>
          <div className='row' >
            <div className='col-6'>
            <div className=' col-12 '>
              <label className='col-4'><b>Delivery Boy Id</b></label>
              <input type='text' className='col-8' name='deliveryBoyId' value={deliveryBoyId} onChange={e => setdeliveryBoyId(e.target.value)} />
            </div>
            <div className='col-12'>
              <label className='col-4'><b> Name </b></label>
              <input type='text' className='col-8' name='deliveryBoyName' value={deliveryBoyName} onChange={e => setdeliveryBoyName(e.target.value)} />
            </div>
            <div className='col-12'>
              <label className='col-4'><b> EmailId </b></label>
              <input type='Email' className='col-8' name='emailId' value={emailId} onChange={e => setemailId(e.target.value)} />
            </div>
            <div className='col-12'>
              <label className='col-4'><b> PhoneNo </b></label>
              <input type='text' className='col-8' name='phoneNumber' value={phoneNumber} onChange={e => setphoneNumber(e.target.value)} />
            </div>
           
              </div>
          </div>
        </div>
          <div className='btn d-flex justify-content-center'>
            {(state === null) ? <Link className="btn2 col-2 col-sm-1" onClick={HandleSubmit} to="/deliverylist"><i className="fa fa-plus" aria-hidden="true"></i> submit</Link> : <>
              <Link className="btn2 col-2 col-sm-1" onClick={HandleEdit} to=""><i className="fa fa-edit"></i> Update </Link>
              <button className="btn2 col-2 col-sm-1" onClick={handleDelete}><i className="fa fa-trash" aria-hidden="true"></i> Delete </button> </>}
            <button className="btn2 col-2 col-sm-1" onClick={HandleCancel}><i className="fa fa-close"></i> Cancel</button>
            <Link className="btn2 col-2 col-sm-1" to="/deliverylist"> <i className="fa fa-sign-out" aria-hidden="true"></i> Close</Link>

          </div>
      </div>
    </>
  )
}

export default Delivery