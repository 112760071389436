import { useEffect, useState } from 'react'
import MasterHeader from '../masterHeader'
import locationService from '../../../services/locationData.service'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import Select from 'react-select'
import LocationHeader from './locationHeader'


const customStyles = {
  option: (provided: any, { isFocused, isSelected }: any) => ({
    ...provided,
    padding: 5,
    zIndex: 1,
    background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected ? 'hsl(0deg 0% 20% / 65%)' : undefined,
  }),
  control: (prev: any) => ({
    ...prev,
    // none of react-select's styles are passed to <Control />
    padding: 0,
    width: "100%"

  }),
  menu: (base: any, { isFocused, isSelected }: any) => ({
    ...base,
    zIndex: 100,
    background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected ? 'hsl(0deg 0% 20% / 65%)' : undefined,
  }),
}
const StateMaster = () => {

  const [stateName, setstateName] = useState("");
  const [stateCode, setstateCode] = useState("");
  const [tin, setTin] = useState("");
  const [allCountry, setAllCountry] = useState([])
  const [countryTableId, setcountryTableId] = useState(0);
  const [countryName, setcountryName] = useState('');

  const { state } = useLocation();

  //  get state by Id
  const getStateById = async () => {
    if (state) {
      try {
        const data = await locationService.getStateById(state);
        if (data) {
          await setstateName(data.stateName);
          await setstateCode(data.stateCode);
          await setcountryTableId(data.countryTableId);
          await setTin(data.tin);
          await getCountry()
        } else {
          setstateName('');
          setstateCode('');
          setcountryTableId(0);
          setTin('');
        }
      } catch (error) {
        console.error(error);
      }
    }
  };
  const getCountry = async () => {
    await locationService.getCountry().then(
      async (data) => {
        const options = data.map((d: any) => ({
          "value": d.id,
          "label": d.countryName
        }))
        await setAllCountry(options)
        if (state === null) {
          if (data[0]) {
            await setcountryTableId(data[0]['id'])
            await setcountryName(data[0]['countryName'])
          }
        } 
      }
    )
  }
  useEffect(()=>{
    let countryNames = allCountry.find((item: any) => {
      return item['value'] === countryTableId
    });
    if (countryNames !== undefined) {
      setcountryName(countryNames['label'])
    }
  },[allCountry, countryTableId])
  useEffect(() => {
    getCountry();
    if (state !== null) {
      getStateById();
    }
  }, [])
  useEffect(() => {
    getCountry();
  }, [])



  const navigate = useNavigate();

  const HandleSubmit = () => {
    locationService.postState(stateName, stateCode, countryTableId, tin).then(
      (data) => {
        if (data) {
          navigate('../statelist');
        }
      }
    ).catch((error) => {
      console.log(error);
    })
  }
  // edit the selected data
  const HandleEdit = () => {
    locationService.putState(stateName, stateCode, countryTableId, tin, state).then(
      (data) => {
        if (data) {
          navigate('../statelist');
        }
      }
    ).catch((error) => {
      console.log(error);
    })
  }
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })

  const HandleDelete = () => {
    if (state !== null)

      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          locationService.deleteState(state).then(
            (data) => {
              if (data.statuscode.message === '200') {
              }
            }
          ).catch((error) => {
            console.log(error);
            // console.log("server responding");

          })
          Swal.fire({
            title: "Deleted",
            icon: 'success',
            text: "State has been deleted",
          }).then(function () {
            navigate('../statelist');
          })
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            {
              title: "Cancelled",
              icon: 'error',
              text: "State is safe :)",
            }).then(function () {
              navigate('../statemaster');
            }
            )
        }
      })
  }

  const HandleCancel = () => {
    setstateName("");
    setstateCode("");
  }
  const handleCountryChange = async (e: any) => {
    await setcountryTableId(e.value)
    await setcountryName(e.label)
  }

  return (
    <>
      <MasterHeader />

      <div style={{ margin: "0px" }}>
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}></div>
        <div className='container col-10' style={{ paddingLeft: "0px" }} >
          <div className="themeColor width" ><p style={{ marginLeft: "10px" }}>State Details </p></div>
          <LocationHeader />
          <div className=' col-sm-12 inputs' style={{ marginTop: "10px" }}>
            <label className='col-6 col-sm-2'><b>State Name</b></label>
            <input type='text' className='col-8 col-sm-4' value={stateName} name='stateName' onChange={e => setstateName(e.target.value)} />
          </div>

          <div className=' col-sm-12 inputs' style={{ marginTop: "10px" }}>
            <label className='col-6 col-sm-2'><b> State Code</b></label>
            <input type='text' className='col-8 col-sm-4' value={stateCode} name='stateCode' onChange={e => setstateCode(e.target.value)} />
          </div>

          <div className=' col-sm-12 inputs' style={{ marginTop: "10px" }}>
            <label className='col-6 col-sm-2'><b> TIN </b></label>
            <input type='text' className='col-8 col-sm-4' value={tin} name='stateCode' onChange={e => setTin(e.target.value)} />
          </div>

          <div className='col-sm-12 inputs'>
            <label className='col-6 col-sm-2'><b> Country </b></label>
            <Select
              name="countryTableId"
              onChange={handleCountryChange}
              options={allCountry}
              className='col-8 col-sm-4 select'
              styles={customStyles}
              value={{ value: countryTableId, label: countryName }}
            />
          </div>
        </div>
        <div className='btn d-flex justify-content-center'>
          {(state === null) ? <Link className="btn2 col-2 col-sm-1" onClick={HandleSubmit} to="../statelist"> <i className="fa fa-plus" aria-hidden="true"> </i>  Submit </Link> : <>
            <Link className="btn2 col-2 col-sm-1" onClick={HandleEdit} to="../statelist"> <i className="fa fa-edit"> </i>   Update </Link>
            <button className="btn2 col-2 col-sm-1" onClick={HandleDelete} > <i className="fa fa-trash"> </i>   Delete</button> </>}
          <button className="btn2 col-2 col-sm-1" onClick={HandleCancel}><i className="fa fa-close"></i>   Cancel</button>
          <Link className="btn2 col-2 col-sm-1" to="/statelist"> <i className="fa fa-sign-out" aria-hidden="true"></i>   Close</Link>
        </div>
      </div>
    </>
  )
}

export default StateMaster
