import React, { useEffect, useState } from 'react'
import MasterHeader from './masterHeader'
import MasterDataMailroomService from '../../services/MasterDataMailroom.service';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2';
import './master1.css'
interface ReceiverProps {
    id: any;
}

const customStyles = {
    option: (provided:any, {isFocused , isSelected } : any) => ({
        ...provided,
        padding: 5,
        zIndex: 1,
        background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected  ? 'hsl(0deg 0% 20% / 65%)' : undefined,
      }),
    control: (prev :any) => ({ ...prev, 
      // none of react-select's styles are passed to <Control />
      padding:0,
      width:"100%"
    }),
    menu: (base : any ,{isFocused , isSelected } : any) => ({
        ...base,
        zIndex: 100,
        background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected  ? 'hsl(0deg 0% 20% / 65%)' : undefined,
      }),
  }

const RouteDetail: React.FC<ReceiverProps> = ({ }) => {
    const { state } = useLocation(); 
  
    const [routeTime, setrouteTime] = useState('')
    const [routeName, setbranchCode] = useState('');   
    const [sunday, setsunday] = useState<number>(0);  
    const [monday, setmonday] = useState<number>(0);   
    const [tuesday, settuesday] = useState<number>(0);   
    const [wednesday, setwednesday] = useState<number>(0);   
    const [thursday, setthursday] = useState<number>(0);   
    const [friday, setfriday] = useState<number>(0);   
    const [saturday, setsaturday] = useState<number>(0);
     
    const handlesunday = (event: React.ChangeEvent<HTMLInputElement>) => {
      setsunday(+event.target.checked);};
    const handlemonday = (event: React.ChangeEvent<HTMLInputElement>) => {
      setmonday(+event.target.checked);};
    const handletuesday = (event: React.ChangeEvent<HTMLInputElement>) => {
      settuesday(+event.target.checked);};
    const handlewednesday = (event: React.ChangeEvent<HTMLInputElement>) => {
      setwednesday(+event.target.checked);};
    const handlethursday = (event: React.ChangeEvent<HTMLInputElement>) => {
      setthursday(+event.target.checked);};
     
      const handlefriday = (event: React.ChangeEvent<HTMLInputElement>) => {
        setfriday(+event.target.checked);};
      const handlesaturday = (event: React.ChangeEvent<HTMLInputElement>) => {
        setsaturday(+event.target.checked);
      };
    const navigate = useNavigate();

    const HandleSubmit = () => {
        MasterDataMailroomService.PostRoute(routeTime, routeName,sunday,monday,tuesday,wednesday,thursday,friday,saturday).then(
            (data) => {
                if (data) {
                    navigate("/routelist");
                }
            },

        ).catch((error) => {
            console.log(error);
            console.log("server Responding");
        })
    }
    const HandleEdit = () => {
        MasterDataMailroomService.PutRoute(routeTime, routeName,sunday,monday,tuesday,wednesday,thursday,friday,saturday, state).then(
            (data) => {
                if (data) {
                    navigate("/route");
                }
            }

        ).catch((error) => {
            console.log(error);
            console.log("server Responding");
        })
    }

    // get service provider by id
    const GetRouteId = async () => {
        if(state){            
        try {
          await MasterDataMailroomService.GetRouteId(state).then(
           async (data) => {
                await setrouteTime(data.routeTime)
                await setbranchCode(data.routeName);
                await setsunday(data.sunday);
                await setmonday(data.monday);
                await settuesday(data.tuesday);
                await setwednesday(data.wednesday);
                await setthursday(data.thursday);
                await setfriday(data.friday);
                await setsaturday(data.saturday);
               
           }
          );
          
        } catch (error) {     
          console.error(error);
        }
        
    } 
      };     

      useEffect(()=>{      
        GetRouteId();   
      },[])
 // delete service
 const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })
        const handleDelete = () => {
            if(state !== undefined){   
              Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
              }).then((result) => {
                if (result.isConfirmed) {
                  MasterDataMailroomService.DeleteBranch(state)
                  .catch((error) => {
                    console.log(error);
                    console.log("server responding");
              
                  })
                  Swal.fire({
                    title: "Deleted",
                    icon: 'success',
                    text: "Service Provider has been deleted",
                }).then(function() {
                 window.location.reload()
                })
                } else if (
                  /* Read more about handling dismissals below */
                  result.dismiss === Swal.DismissReason.cancel
                )  {
                  swalWithBootstrapButtons.fire(
                    {
                      title: "Cancelled",
                      icon: 'error',
                      text: "Service Provider is safe :)",
                  }).then(function() {          
                 window.location.reload()
                  }
                  )
                }
              })
            }  
            GetRouteId();
        } 

        const HandleCancel = () =>{
            setrouteTime("")
            setbranchCode("")
            
          }
    return (
        <>
            <MasterHeader />
            <div className='container col-10' >
            <div className="themeColor" style={{ width: '100%'}} ><p style={{ marginLeft: "10px" }}>Service Provider </p></div>

                <div className=' col-sm-12 inputs'>
                    <label className='col-6 col-sm-2'><b>Route Time</b></label>
                    <input type='time' className='col-8 col-sm-4' name='routeTime' value={routeTime} onChange={e => setrouteTime(e.target.value)} />
                </div>
                <div className=' col-sm-12 inputs'>
                    <label className='col-6 col-sm-2'><b>Route Name</b></label>
                    <input type='text' className='col-8 col-sm-4'  name='routeName' value={routeName} onChange={e => setbranchCode(e.target.value)} />
                </div>               
                <div className=' col-sm-12 inputs'>
                <label className='col-6 col-sm-2'><b>Sunday</b></label>
                {/* <input type='checkbox' className='col-8 col-sm-4' name='sunday' */}
                {/* //  checked={sunday} onChange={e => setsunday(e.target.checked)} /> */}
                <input
                className='col-8 col-sm-4' name='sunday' type="checkbox" checked={sunday === 1} onChange={handlesunday}/>
            </div>              
                <div className=' col-sm-12 inputs'>
                    <label className='col-6 col-sm-2'><b>Monday</b></label>
                    <input type='checkbox' className='col-8 col-sm-4'  name='monday'  checked={monday === 1} onChange={handlemonday} />
                </div>               
                <div className=' col-sm-12 inputs'>
                    <label className='col-6 col-sm-2'><b>Tuesday</b></label>
                    <input type='checkbox' className='col-8 col-sm-4'  name='tuesday'  checked={tuesday === 1} onChange={handletuesday} />
                </div>               
                <div className=' col-sm-12 inputs'>
                    <label className='col-6 col-sm-2'><b>Wednesday</b></label>
                    <input type='checkbox' className='col-8 col-sm-4'  name='wednesday' checked={wednesday === 1} onChange={handlewednesday} />
                </div>               
                <div className=' col-sm-12 inputs'>
                    <label className='col-6 col-sm-2'><b>Thursday</b></label>
                    <input type='checkbox' className='col-8 col-sm-4'  name='thursday' checked={thursday === 1} onChange={handlethursday} />
                </div>               
                <div className=' col-sm-12 inputs'>
                    <label className='col-6 col-sm-2'><b>Friday</b></label>
                    <input type='checkbox' className='col-8 col-sm-4'  name='friday'checked={friday === 1} onChange={handlefriday} />
                </div>               
                <div className=' col-sm-12 inputs'>
                    <label className='col-6 col-sm-2'><b>Saturday</b></label>
                    <input type='checkbox' className='col-8 col-sm-4'  name='saturday'checked={saturday === 1} onChange={handlesaturday}/>
                </div>               
            </div>
            <div className='btn d-flex justify-content-center'>
            { (state === null) ? <Link className="btn2 col-2 col-sm-1" onClick={HandleSubmit} to=""><i className="fa fa-plus" aria-hidden="true"></i> Submit</Link> : <>
                <Link className="btn2 col-2 col-sm-1" onClick={HandleEdit} to="/serviceproviderlist"><i className="fa fa-edit"></i> Update </Link>
               <button className="btn2 col-2 col-sm-1" onClick={handleDelete} ><i className="fa fa-trash" aria-hidden="true"></i> Delete</button> </> }
                <button className="btn2 col-2 col-sm-1" onClick={HandleCancel} ><i className="fa fa-close"></i> Cancel</button>
                <Link className="btn2 col-2 col-sm-1" to="/serviceproviderlist"> <i className="fa fa-sign-out" aria-hidden="true"></i> Close</Link>
            </div>
        </>
    )
}

export default RouteDetail