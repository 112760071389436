import React, { useEffect, useState } from 'react'
import SettingHeader from './settingHeader';
import MasterDataMailroomService from '../../services/MasterDataMailroom.service';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2';

interface ReceiverProps {
    id: any;
}

const Branch: React.FC<ReceiverProps> = ({ }) => {
    const { state } = useLocation(); 
  
    const [branchName, setbranchName] = useState('')
    const [branchCode, setbranchCode] = useState('');
   
    const navigate = useNavigate();

    const HandleSubmit = () => {
        MasterDataMailroomService.PostBranch(branchName, branchCode).then(
            (data) => {
                if (data !== '') {
                    navigate("../branchlist");
                }
            },

        ).catch((error) => {
            console.log(error);
            console.log("server Responding");
        })
    }
    const HandleEdit = () => {
        MasterDataMailroomService.PutBranch(branchName, branchCode, state).then(
            (data) => {
                if (data !== '') {
                    navigate("../branchlist");
                }
            }

        ).catch((error) => {
            console.log(error);
            console.log("server Responding");
        })
    }

    // get service provider by id
    const GetBranchId = async () => {
        if(state){            
        try {
          await MasterDataMailroomService.GetBranchId(state).then(
           async (data) => {
                await setbranchName(data.branchName)
                await setbranchCode(data.branchCode);
               
           }
          );
          
        } catch (error) {     
          console.error(error);
        }
        
    } 
      };
      

      useEffect(()=>{      
        GetBranchId();   
      },[])
 // delete service
 const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })
        const handleDelete = () => {
            if(state !== undefined){   
              Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
              }).then((result) => {
                if (result.isConfirmed) {
                  MasterDataMailroomService.DeleteBranch(state)
                  .catch((error) => {
                    console.log(error);
                    console.log("server responding");
              
                  })
                  Swal.fire({
                    title: "Deleted",
                    icon: 'success',
                    text: "Branch has been deleted",
                }).then(function() {
                  navigate("../branchlist")
                })
                } else if (
                  /* Read more about handling dismissals below */
                  result.dismiss === Swal.DismissReason.cancel
                )  {
                  swalWithBootstrapButtons.fire(
                    {
                      title: "Cancelled",
                      icon: 'error',
                      text: "Branch is safe :)",
                  }).then(function() {          
                navigate("../branchlist")
                  }
                  )
                }
              })
            }  
            GetBranchId();
        } 

        const HandleCancel = () =>{
            setbranchName("")
            setbranchCode("")
            
          }
    return (
        <>
            <SettingHeader />
            <div className='container col-10'style={{ paddingLeft: "0px" }} >
            <div className="themeColor" style={{ width: '100%'}} ><p style={{ marginLeft: "10px" }}>Branch Register </p></div>

                <div className=' col-sm-12 inputs'>
                    <label className='col-6 col-sm-2'><b> Branch Name</b></label>
                    <input type='text' className='col-8 col-sm-4' name='branchName' value={branchName} onChange={e => setbranchName(e.target.value)} />
                </div>
                <div className=' col-sm-12 inputs'>
                    <label className='col-6 col-sm-2'><b> Branch Code</b></label>
                    <input type='text' className='col-8 col-sm-4'  name='branchCode' value={branchCode} onChange={e => setbranchCode(e.target.value)} />
                </div>

               
            </div>
            <div className='btn d-flex justify-content-center'>
            { (state === null) ? <Link className="btn2 col-2 col-sm-1" onClick={HandleSubmit} to="/branchlist"><i className="fa fa-plus" aria-hidden="true"></i> Submit</Link> : <>
                <Link className="btn2 col-2 col-sm-1" onClick={HandleEdit} to="/branchlist"><i className="fa fa-edit"></i> Update </Link>
               <button className="btn2 col-2 col-sm-1" onClick={handleDelete} ><i className="fa fa-trash" aria-hidden="true"></i> Delete</button> </> }
                <button className="btn2 col-2 col-sm-1" onClick={HandleCancel} ><i className="fa fa-close"></i> Cancel</button>
                <Link className="btn2 col-2 col-sm-1" to="/branchlist"> <i className="fa fa-sign-out" aria-hidden="true"></i> Close</Link>
            </div>
        </>
    )
}

export default Branch